 // Open or create the database and its object stores
 export const openDatabase = () => {
    return new Promise((resolve, reject) => {
      if (!('indexedDB' in window)) {
        reject('IndexedDB not supported');
        return;
      }

      const request = window.indexedDB.open('Temp', 1);

      request.onerror = () => reject(request.error);
      request.onsuccess = () => resolve(request.result);
      request.onupgradeneeded = (event) => {
        const db = event.target.result;
       
        if (!db.objectStoreNames.contains('files')) {
          db.createObjectStore('files', { keyPath: 'id', autoIncrement: true});

        }
        if (!db.objectStoreNames.contains('manifest')) {
          db.createObjectStore('manifest', { keyPath: 'ProdId'  });
        }
      };
      
    });
  };



export const createProduct = async (productId) => {
  const db = await openDatabase();
  const transaction = db.transaction('manifest', 'readwrite');
  const store = transaction.objectStore('manifest');
  
  return new Promise((resolve, reject) => {
    const request = store.add({ ProdId : productId, files: [] });
    request.onsuccess = () => resolve(request.result);
    request.onerror = () => reject(request.error);

  });
};

export const prodExists = async (productId) => {
  const db = await openDatabase();
  const transaction = db.transaction('manifest', 'readonly');
  const store = transaction.objectStore('manifest');
  const request = store.get(productId);
  if (request.result) {
    return true;
  }
  return false;
};

export const getManifest = (prodId) => {
  if (!prodId) { // Check if prodId is null, undefined, or an empty string
    return Promise.reject(new Error("Product ID is invalid"));
  }

  return new Promise((resolve, reject) => {
    openDatabase() // Make sure the database is opened first.
      .then((db) => {
        const transaction = db.transaction('manifest', 'readonly');
        const store = transaction.objectStore('manifest');
        const request = store.get(prodId);

        request.onerror = () => reject(request.error);
        request.onsuccess = () => {
          resolve(request.result);
        };
      })
      .catch((error) => {
        reject(error);
      });
  });
};


export const addOrUpdateCategory = async (productId, newCategory) => {
  const db = await openDatabase();
  const transaction = db.transaction('manifest', 'readwrite');
  const store = transaction.objectStore('manifest');
  const request = store.get(productId);

  return new Promise((resolve, reject) => {
    request.onsuccess = () => {
      const data = request.result;
      if (!data) {
        reject(new Error(`No product found with productId ${productId}`));
        return;
      }
      // Initialize categories if not already present
      if (!data.categories) {
        data.categories = [];
      }
      // Find the index of the category in the array
      const index = data.categories.indexOf(newCategory);
      if (index === -1) {
        // Category not found, add it
        data.categories.push(newCategory);
      } else {
        // Category found, remove it
        data.categories.splice(index, 1);  // Correct way to remove an item
      }
      const updateRequest = store.put(data);
      updateRequest.onsuccess = () => resolve(updateRequest.result);
      updateRequest.onerror = () => reject(updateRequest.error);
    };
    request.onerror = () => reject(request.error);
  });
};









export const addFileToProduct = async (productId, fileBlob, fileInfo) => {
  const db = await openDatabase();
  const transaction = db.transaction(['manifest', 'files'], 'readwrite');
  const manifestStore = transaction.objectStore('manifest');
  const filesStore = transaction.objectStore('files');

  return new Promise((resolve, reject) => {
    if (fileInfo.type === "application/pdf") {
      // Step 1: Store the PDF file Blob in the `files` store
      const fileAddRequest = filesStore.add(fileBlob);
  
      fileAddRequest.onsuccess = (e) => {
        const fileId = e.target.result; // Auto-generated ID for the Blob in the `files` store
  
        // Step 2: Retrieve or initialize the manifest entry for the productId
        const manifestRequest = manifestStore.get(productId);
        manifestRequest.onsuccess = () => {
          const manifestData = manifestRequest.result || { ProdId: productId, files: [] };
  
          // Step 3: Include file metadata in the manifest, with the file ID reference
          const fileMetadataWithId = {
            ...fileInfo,
            fileId: fileId // Reference to the Blob stored in the `files` store
          };
          
          manifestData.files.push(fileMetadataWithId); // Add the file metadata to the manifest
  
          // Step 4: Update the manifest in the database
          const updateRequest = manifestStore.put(manifestData);
          updateRequest.onsuccess = () => resolve(updateRequest.result);
          updateRequest.onerror = () => reject(updateRequest.error);
        };
        manifestRequest.onerror = () => reject(manifestRequest.error);
      };
      fileAddRequest.onerror = () => reject(fileAddRequest.error);
    } if (fileInfo.type === "image/jpeg" || fileInfo.type === "image/png" || fileInfo.type === "image/svg+xml" || fileInfo.type === "image/webp" ){
      // Step 1: Store the image file Blob in the `files` store
      const fileAddRequest = filesStore.add(fileBlob);
  
      fileAddRequest.onsuccess = (e) => {
        const fileId = e.target.result; // Auto-generated ID for the Blob in the `files` store
  
        // Step 2: Retrieve or initialize the manifest entry for the productId
        const manifestRequest = manifestStore.get(productId);
        manifestRequest.onsuccess = () => {
          const manifestData = manifestRequest.result || { productId, files: [] };
  
          // Step 3: Include file metadata in the manifest, with the file ID reference
          const fileMetadataWithId = {
            ...fileInfo,
            fileId: fileId // Reference to the Blob stored in the `files` store
          };
          
          manifestData.files.push(fileMetadataWithId); // Add the file metadata to the manifest
  
          // Step 4: Update the manifest in the database
          const updateRequest = manifestStore.put(manifestData);
          updateRequest.onsuccess = () => resolve(updateRequest.result);
          updateRequest.onerror = () => reject(updateRequest.error);
        };
        manifestRequest.onerror = () => reject(manifestRequest.error);
      };
      fileAddRequest.onerror = () => reject(fileAddRequest.error);

    } else {
      reject(new Error("Unsupported file type"));
    }
  });
};





export async function getFilesByProductId(productId, category) {
  const db = await openDatabase();
  const transaction = db.transaction(['manifest', 'files'], 'readonly');
  const manifestStore = transaction.objectStore('manifest');
  const filesStore = transaction.objectStore('files');

  return new Promise((resolve, reject) => {
    const manifestRequest = manifestStore.get(productId);

    manifestRequest.onsuccess = () => {
      const manifestData = manifestRequest.result;
      if (!manifestData) {
        reject(new Error(`No product found with productId ${productId}`));
        return;
      }

      // Filter files by category before making requests to the 'files' store
      const filteredFileMetadatas = manifestData.files.filter(file => file.category === category);

      // Retrieve the file Blobs from the `files` store using the IDs in the manifest
      const filePromises = filteredFileMetadatas.map(fileMetadata => {
        const fileRequest = filesStore.get(fileMetadata.fileId);
        return new Promise((resolve, reject) => {
          fileRequest.onsuccess = () => {
            const fileBlob = fileRequest.result;
            if (fileBlob) {
              resolve({ ...fileMetadata, content: fileBlob });
            } else {
              reject(new Error(`No file found with id ${fileMetadata.fileId}`));
            }
          };
          fileRequest.onerror = () => reject(fileRequest.error);
        });
      });

      Promise.all(filePromises)
        .then(files => resolve(files)) // Resolve with the array of files
        .catch(reject);
    };

    manifestRequest.onerror = () => reject(manifestRequest.error);
  });
}








export async function deleteManifestAndFiles(productId) {
  const db = await openDatabase();
  const transaction = db.transaction(['manifest', 'files'], 'readwrite');
  const manifestStore = transaction.objectStore('manifest');
  const filesStore = transaction.objectStore('files');

  return new Promise((resolve, reject) => {
    const manifestRequest = manifestStore.get(productId);

    manifestRequest.onsuccess = () => {
      const manifestData = manifestRequest.result;
      if (!manifestData) {
        reject(new Error(`No product found with productId ${productId}`));
        return;
      }

      // Delete each file referenced in the manifest from the `files` store
      manifestData.files.forEach(fileId => {
        filesStore.delete(fileId);
      });

      // Finally, delete the manifest entry
      const deleteRequest = manifestStore.delete(productId);
      deleteRequest.onsuccess = () => resolve();
      deleteRequest.onerror = () => reject(deleteRequest.error);
  }
  manifestRequest.onerror = () => reject(manifestRequest.error);
});
}

export async function deleteFileFromProduct(productId, id) {
  const db = await openDatabase();
  const transaction = db.transaction(['manifest', 'files'], 'readwrite');
  const manifestStore = transaction.objectStore('manifest');
  const filesStore = transaction.objectStore('files');

  return new Promise((resolve, reject) => {
    const manifestRequest = manifestStore.get(productId);

    manifestRequest.onsuccess = () => {
      const manifestData = manifestRequest.result;
      if (!manifestData) {
        reject(new Error(`No product found with productId ${productId}`));
        return;
      }

      // Find the index of the file with the specified fileId
      const fileIndex = manifestData.files.findIndex(file => file.fileId === id);
      if (fileIndex === -1) {
        reject(new Error(`No file found with fileId ${id} in product ${productId}`));
        return;
      }

      manifestData.files.splice(fileIndex, 1); // Remove the file object from the array
      const updateRequest = manifestStore.put(manifestData); // Update the manifest

      updateRequest.onsuccess = () => {
        const deleteRequest = filesStore.delete(id); // Delete the file from the `files` store
        deleteRequest.onsuccess = () => resolve();
        deleteRequest.onerror = () => reject(deleteRequest.error);
      };
      updateRequest.onerror = () => reject(updateRequest.error);
    };
    manifestRequest.onerror = () => reject(manifestRequest.error);
  });
}


export async function getfilesbycategory(productid) {
  const db = await openDatabase();
  const transaction = db.transaction('manifest', 'readonly');
  const store = transaction.objectStore('manifest');
  const request = store.get(productid);

  return new Promise((resolve, reject) => {
      request.onsuccess = () => {
          if (request.result) {
              resolve(request.result);
          } else {
              reject(new Error(`No item found with id ${productid}`));
          }
      }
      request.onerror = () => reject(request.error);
  });
}







