import styles from './NumberWithHover.module.css';

export const NumberWithHover = ({ number }) => {
    // Check if the number is defined and is a number
    if (number === null || number === undefined) {
      return <div className={styles.numbercontainer}>Invalid data</div>;
    }
  number = parseFloat(number);
    // Converts the number to scientific notation
    const scientificNumber = number.toExponential(1);
  
    // Formats the number to a fixed number of decimal places for the tooltip
    const fullNumber = number.toFixed(2);
  
    return (
      <div className={styles.numbercontainer} title={fullNumber}>
        <div style={{ width: '60px' }}>{scientificNumber}</div> {/* Set a fixed width */}
      </div>
    );
  };
  
