import React, { useState, useRef, useEffect } from 'react';
import { NavLink,useLocation , useNavigate    } from "react-router-dom";
import { AiOutlineLock, AiOutlineUnlock} from 'react-icons/ai';
import '../../Fonts/GOTHICB.woff'
import {IoPersonSharp} from 'react-icons/io5';
import {MdCollectionsBookmark} from 'react-icons/md';
import { useGlobalContext } from '../../GlobalContext';


function Navbar() {
  const location = useLocation();
  const [hoveredLink, setHoveredLink] = useState(null);
  const hoverTimeoutRef = useRef(null);
  const navigate = useNavigate(); // Use the useNavigate hook to get access to the navigate function
  const isLoggedIn = !!localStorage.getItem('sessionToken');
  const {userType, setUserType} = useGlobalContext();
  
  
  const isProd = userType?.authRole === "PRODUCENT";
    

  const colorCodes = {
    Green: "#334C31",
    Sand: "#AE8C65",
    White: "#F5F5F7",
    Black: "#212426",
    Grey: "#D7D5D6"
};

  const navStyle = {
    backgroundColor: colorCodes.White,
    margin: 'auto',
    width: 'auto',
    minWidth: "730px",
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderBottom: `5px solid var(--Sand)`,
    paddingTop: "20px",
    paddingBottom: "1px",
    height: '15vh',
  };

  const linksContainerStyle = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flex: 1, // this helps in distributing the space equally
  };

  const linkStyle = {
    marginLeft: "15px",
    fontFamily: 'AMIA, sans-serif',  // Corrected here
    textDecoration: "none",
    fontWeight: '1000',
    color: colorCodes.Sand,
    fontSize: '130%',
    listStyle: 'none',
    padding: '15px 5px', 
    borderRadius: '10px 10px 0 0',
    transition: 'background-color 0.3s', 
    minWidth: '113px',
};


  const activeLinkStyle = {
    textDecoration: 'none',
    color: colorCodes.Green,
    backgroundColor: colorCodes.White, // Background color for the active link
    borderRadius: '10px 10px 0 0', // Rounded corners on the top
    border: `2px solid var(--Sand)` // Border for the rectangle
  };
  

  const iconStyle = {
    marginRight: '10px', // Space between the icon and the button
    color: colorCodes.Sand,
  };

  const buttonStyle = {
    display: 'flex',
    color: colorCodes.Sand,
    alignItems: 'center', 
    textAlign: 'center',
    fontWeight: '700',
    textDecoration: 'none',
    background: 'none',
    cursor: 'pointer',
    fontSize: 'inherit',
    
    marginRight: '20px',
  };



  const links = [
    { path: "/", label: "Hjem" },
    isLoggedIn && !isProd ? { path: "/kategori", label: "Kategori" }: null,
    { path: "/vision", label: "Vision" },
    { path: "/om_os", label: "Om Os" },
    // { path: "/help", label: "Hjælp" }
  ].filter(Boolean); // Filter out any null values


  const handleMouseEnter = (path) => {
    if (hoverTimeoutRef.current) {
      clearTimeout(hoverTimeoutRef.current); // Clear any existing timeout when hovering over a new link
    }
    setHoveredLink(path);
  }
  const handleMouseLeave = () => {
    if (hoverTimeoutRef.current) {
      clearTimeout(hoverTimeoutRef.current);
    }
    hoverTimeoutRef.current = setTimeout(() => {
      setHoveredLink(null);
    }, 250); // 200ms delay before reverting back to the active link style
  }
  const handleLogout = () => {
    localStorage.removeItem('sessionToken'); // Remove the sessionToken from localStorage
    localStorage.removeItem('selectedProjects'); // Remove the selectedProjects from localStorage
    navigate('/login'); // Redirect to the login page using navigate
  };
  useEffect(() => {
    return () => {
      if (hoverTimeoutRef.current) {
        clearTimeout(hoverTimeoutRef.current);
      }
    };
  }, []);

  useEffect(() => {
    const navLinkContainers = document.querySelectorAll('.navLinkButton');
  
    const handleMouseOver = (event) => {
      const targetContainer = event.currentTarget; // Get the div container
      const targetLink = targetContainer.querySelector('a'); // Get the NavLink inside the div
      const icon = targetContainer.querySelector('svg'); // Get the icon inside the div
      if (targetLink) {
        targetLink.style.color = colorCodes.Green;
      }
      if (icon) {
        icon.style.color = colorCodes.Green;
      }
    };
  
    const handleMouseOut = (event) => {
      const targetContainer = event.currentTarget; // Get the div container
      const targetLink = targetContainer.querySelector('a'); // Get the NavLink inside the div
      const icon = targetContainer.querySelector('svg'); // Get the icon inside the div
      if (targetLink) {
        targetLink.style.color = colorCodes.Sand;
      }
      if (icon) {
        icon.style.color = colorCodes.Sand;
      }
    };
  
    navLinkContainers.forEach(container => {
      container.addEventListener('mouseover', handleMouseOver);
      container.addEventListener('mouseout', handleMouseOut);
    });
  
    // Cleanup - remove event listeners when component unmounts
    return () => {
      navLinkContainers.forEach(container => {
        container.removeEventListener('mouseover', handleMouseOver);
        container.removeEventListener('mouseout', handleMouseOut);
      });
    };
  }, [colorCodes.Green, colorCodes.Sand]);
  
  


  return (
    <nav style={navStyle}>
      <div>
        <img src="/images/hovedelogo.svg" alt="Logo" style={{ width: '7vw', marginLeft: "3vw" , color: colorCodes.Sand }} />
      </div>
            <ul style={linksContainerStyle}>
          {links.map(link => {
            const isActive = location.pathname === link.path || location.pathname.startsWith(`${link.path}/`);
            return (
              <li 
                key={link.path} 
                style={linkStyle}
              >
                <NavLink 
                    to={link.path} 
                    style={
                      hoveredLink === link.path 
                        ? {...linkStyle, ...activeLinkStyle} 
                        : isActive && hoveredLink === null
                          ? {...linkStyle, ...activeLinkStyle} 
                          : linkStyle
                    }
                    onMouseEnter={() => handleMouseEnter(link.path)}
                    onMouseLeave={handleMouseLeave}
                  >
                    {link.label}
                  </NavLink>


              </li>
            );
          })}
        </ul>
       

        <div style={{ display: 'flex', alignItems: 'center' }}>
        {isLoggedIn ? (
          <>  
        <div>
            {isProd ? (
              <div style={{display:"flex" }}>
              <div className="navLinkButton">
              <NavLink to="/produkter" style={buttonStyle}> <MdCollectionsBookmark size={20}/>Mine produkter</NavLink>
              </div>
              <div className="navLinkButton">
              <NavLink to="/produkter/add" style={buttonStyle}> <MdCollectionsBookmark size={20}/>Tilføj Produkter</NavLink>
              </div>
              </div>

            ) : (
            <NavLink to="/projekter" className="navLinkButton" style={buttonStyle}> <MdCollectionsBookmark size={20}/>Mine projekter</NavLink>
            )}

          </div>

          <div className="navLinkButton">
            <NavLink to="/profile"  style={buttonStyle}> <IoPersonSharp size={20}/>Min profil</NavLink>
          </div>

          <div className="navLinkButton"> 
            <NavLink to="/" onClick={handleLogout}  style={buttonStyle}> <AiOutlineUnlock size={24}/>Log ud</NavLink>
          </div>
          </>
        ) : (
          // If not logged in, show "Login/Register" link
          <NavLink to="/login" className="navLinkButton" style={buttonStyle}> 
            <AiOutlineLock style={iconStyle} size={32} />  Login/Registrer
          </NavLink>
        )}
      </div>
    </nav>
  );
}


export default Navbar;
