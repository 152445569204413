//search bar loop
import { GrSearch } from "react-icons/gr";
// delete button
import { MdDeleteForever } from "react-icons/md";
import { CiSquarePlus } from 'react-icons/ci';
import { PiPencilSimpleLineDuotone } from "react-icons/pi";
import { IoStatsChart } from "react-icons/io5";
import { MdPayment } from "react-icons/md";
import { IoIosPaper } from "react-icons/io";
import { TbLogout2 } from "react-icons/tb";
import { ImCheckboxChecked, ImCheckboxUnchecked } from 'react-icons/im';
import { IoIosArrowDown } from 'react-icons/io'; // You can use any arrow icon
import {IoPersonSharp} from 'react-icons/io5';
import {MdCollectionsBookmark} from 'react-icons/md';
import { AiOutlineLock, AiOutlineUnlock} from 'react-icons/ai';
import { AiOutlineLoading } from 'react-icons/ai';
import { FaPlus } from 'react-icons/fa';
import { ImCheckmark } from 'react-icons/im';
import { AiOutlineEdit } from "react-icons/ai";

function Showallicons(){

    return (
        <div>
            <GrSearch />
            <MdDeleteForever />
            <PiPencilSimpleLineDuotone />
            <IoStatsChart />
            <MdPayment />
            <IoIosPaper />
            <TbLogout2 />
            <ImCheckboxChecked />
            <ImCheckboxUnchecked />
            <IoIosArrowDown />
            <IoPersonSharp />
            <MdCollectionsBookmark />
            <AiOutlineLock />
            <AiOutlineUnlock />
            <AiOutlineLoading />
            <CiSquarePlus />
            <FaPlus />
            <ImCheckmark />
            <AiOutlineEdit/>
        </div>


    );





}

export default Showallicons;