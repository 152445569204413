import { useParams, useNavigate, useLocation } from 'react-router-dom';
import React, { useState, useEffect, useCallback } from 'react';
import { fetchDatafromQuery, fetchQueryDataCat } from '../../../components/APIS/categoryAPI';
import MemoizedDetailBoxes from '../LoadedProducts';
import FilterQuery from '../Filter/Filter';
import './kategoridetailstyle.css';

import { IoIosArrowBack,IoIosArrowForward } from 'react-icons/io';



function KategoriDetailPage() {
  const {id} = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const [products, setProducts] = useState([]);
  const [dat, setDat] = useState({});
  const [query, setQuery] = useState({});
  const navigate = useNavigate();
  const location = useLocation();
  const [unit, setUnit] = useState({});
  const [availableUnits, setAvailableUnits] = useState([]);
  const [orgData, setOrgData] = useState({});
  const [page, setPage] = useState(1);
  const [maxPage, setMaxPage] = useState(1);



 // Fetch data on component load
useEffect(() => {
  const fetchData = async () => {
    setIsLoading(true);
    try {
      // Fetch the data based on the ID
      const lcID = id.toLowerCase();
      const data = await fetchQueryDataCat({ id: lcID });
      setOrgData(data);

      // Check for available units
      const availableUnits = data.map((item) => item.unit);
      setAvailableUnits([...new Set(availableUnits)]);
      let selectedUnit = "";
      // Select 'kg' if available, otherwise pick a random unit
      if (availableUnits.includes("kg")) {
        selectedUnit = "kg";
      } else {
        selectedUnit = availableUnits[Math.floor(Math.random() * availableUnits.length)];
      }
      // Filter data based on the selected unit
      const selectedData = data.filter((item) => item.unit === selectedUnit);
      // Select a single object from the filtered data
      const selectedObject = selectedData.length > 0 ? selectedData[0] : null;
      // console.log('selectedObject:', selectedObject);

      // Update state with the selected unit and the single object
      setUnit(selectedUnit);
      setDat(selectedObject);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setIsLoading(false);
    }
  };

  fetchData();
}, []);

const handleUnitChange = (e) => {
  // console.log('Unit changed:', e.target.value);
  const selectedData = orgData.filter((item) => item.unit === e.target.value);
  const selectedObject = selectedData.length > 0 ? selectedData[0] : null;
  setUnit(e.target.value);
  setDat(selectedObject);
}




const handleUnitBox = () => {
  return (
    <div className='unitBox'>
      {availableUnits.map((unitOption) => (
        <div
          key={unitOption}
          onClick={() => handleUnitChange({ target: { value: unitOption } })}
          className={`unit-option ${unit === unitOption ? 'selected' : ''}`}
        >
          {unitOption}
        </div>
      ))}
    </div>
  );
};


const pagePlus = () => {
  setPage(prevPage => Math.min(prevPage + 1, maxPage));
};

const pageMinus = () => {
  setPage(prevPage => Math.max(prevPage - 1, 1));
};

const renderPagebtn = (max, current) => {
  if (max === 1) {
    return <p className='pageNumber'>Side: 1</p>;
  }

  return (
    <div className='numberBox'>
      {/* Show the Previous button only if the current page is greater than 1 */}
      {current > 1 && (
        <button className='navButton' onClick={pageMinus}> < IoIosArrowBack className="arrowIcon"/> </button>
      )}

      {/* Show the current page number */}
      <p className='pageNumber'>Side: {current}</p>

      {/* Show the Next button only if the current page is less than max */}
      {current < max && (
        <button className='navButton' onClick={pagePlus}><IoIosArrowForward className="arrowIcon" /></button>
      )}

    </div>
  );
};



  return (
    <div className="page-container">
      <div className="filter-container">
        <h3>Filter</h3>
        <div >
        {!isLoading && dat && (
        <FilterQuery 
          setQuery={setQuery}
          data={dat} 
          setProducts={setProducts}
          pageN={page}
          setMaxPage={setMaxPage}

        />
      )}
        </div>
      </div>

      <div className="content-container">
        <div id="title_and_buttons">
          <div id="kategori-title">
            <h1>{id}</h1>
          </div>
          <div style={{padding:"8px"}}>
            {!isLoading && dat && handleUnitBox()}
          </div>
      
        </div>
        <div>
          {!isLoading && dat && renderPagebtn(maxPage, page)}
        </div>
        <div className="detail-box-group">
          <MemoizedDetailBoxes
            isLoading={isLoading}
            products={products}
          />
        </div>
        <div>
          {!isLoading && dat && renderPagebtn(maxPage, page)}
        </div>
      </div>
    </div>
  );
}

export default KategoriDetailPage;
