import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Navbar from './components/Utils/navbar';
import MainPage from './pages/MainPage';
import KategoriPage from './pages/KategoriPage';
import VisionPage from './pages/VisionPage';
import OmPage from './pages/OmPage';
import FaqPage from './pages/FaqPage';

import ProjektPage from './pages/ProjektPage'
import KategoriDetailPage from './pages/Kategori/kategoridetailpage/KategoriDetailPage';
import DetailPage from './pages/Kategori/DetailPage';
import LoginPage from './pages/LoginPage';
import './App.css';
import { GlobalProvider } from './GlobalContext';
import RegisterPage from './pages/RegisterPage';
import Profile from './pages/Profilepage';
import ProjectDetails from './pages/Project/ProjectDetails'
import Settings from './pages/ProfileMenu/settings';
import {ProtectedRoute} from './components/auth';
import Feeter from './components/Utils/footer';
import Dev from './pages/dev';
import UnderConstruction from './pages/Construction';
import UploadTable from './pages/Upload/UploadTable';
import ProdStart from './pages/Upload/ProdStart';
import Correctpage from './pages/Upload/Correctpage';

function App() {
  
  return (
    <GlobalProvider>
    <Router>
      <Navbar /> 
      <div className="main-content">
        <Routes>

          <Route path="/" element={<MainPage />} />
         
          <Route path="/vision" element={<VisionPage />} />
          <Route path="/om_os" element={<OmPage />} />
          <Route path="/help" element={<FaqPage />} />
          <Route path="/login" element={<LoginPage />} />
          <Route path="*" element={<h1>404 Not Found</h1>} />
          <Route path="/register" element={<RegisterPage />} />
          {/* protected routes */}
          <Route path="/register" element={<RegisterPage />} />
            <Route path="/profile" element={<ProtectedRoute element={Profile} />} />
            <Route path="/project/:projectId" element={<ProtectedRoute element={ProjectDetails} />} />
            <Route path="/profile/settings" element={<ProtectedRoute element={Settings} />} />
            <Route path="/kategori" element={<ProtectedRoute element={KategoriPage} />} />
            <Route path="/kategori/:id" element={<ProtectedRoute element={KategoriDetailPage} />} />
            <Route path="/produkt/:id" element={<ProtectedRoute element={DetailPage} />} />
            <Route path="/projekter" element={<ProtectedRoute element= {ProjektPage} />} /> 
            <Route path="/produkter" element={<ProtectedRoute element = {UploadTable}/>}/>
            <Route path='/produkter/correct/:id' element={<ProtectedRoute element={Correctpage}/>}/>
            {/* <Route path="/dev" element={<ProtectedRoute element={Dev} />} /> */}
            <Route path="/Dev" element={<Dev />} />
            <Route path="/produkter/add" element={<ProtectedRoute element={ProdStart}/>} />
            <Route path="/produkter" element={<ProtectedRoute element={MainPage} />} />
            {/* <Route path="*" element={<UnderConstruction />} /> */}
          
        </Routes>
      </div>
    </Router>
      <Feeter />
    
    </GlobalProvider>
  );
}
  
export default App;
