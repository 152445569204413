import React, { useEffect, useState } from "react";
import TableRow from "./Row/TableRow";
import Loader from "../../components/Utils/Loader";
import "../styles/TableUpload.css";
import { createProduct, prodExists } from "../../components/APIS/indexedDBUtils";
import { getClaimedEPD, GetCorrectedEPD } from "../../components/APIS/uploadAPI";
import { fetchAllCategories, fetchSubcategories } from "../../components/APIS/categoryAPI";
import SearchEngineID from "./SearchEngine/SearchEngineID";
import ProdStart from "./ProdStart";
import RadioButtonsGroup from "../../components/radiobuttongroup";
import UploadComponent from "./Row/UploadRow";
import ProcessingComponent from "./Row/ProcessRow";

function UploadTable() {
    const [radioValue, setRadioValue] = useState('Egne produkter');
    const [products, setProducts] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [categoryStructure, setCategoryStructure] = useState([]);
    const [expandedProductId, setExpandedProductId] = useState(null);


    const LoadClaimProducts = async () => {
        try {
            let data = await getClaimedEPD();

            if (!Array.isArray(data)) {
                // Handle different response types accordingly
                console.error('Unexpected data format:', data);
                throw new Error('Expected an array of products.');
            }
            console.log(data);
            const productIds = data.map((product) => product.id);
            // console.log(productIds);
            setProducts(data);

            setLoading(false);
        } catch (error) {
            setError(error);
            setLoading(false);
        }
    };

    const loadCorrectedProducts = async () => {
        try {
            let data = await GetCorrectedEPD();

            if (!Array.isArray(data)) {
                // Handle different response types accordingly
                console.error('Unexpected data format:', data);
                throw new Error('Expected an array of products.');
            }
            const productIds = data.map((product) => product.id);
            // console.log(productIds);
            setProducts(data);

            setLoading(false);
        } catch (error) {
            setError(error);
            setLoading(false);
        }
    };

    useEffect(() => {
        LoadClaimProducts();
    }, []);

    const handleRadioChange = async (e) => {
        const selectedValue = e.target.value;
        setRadioValue(selectedValue);

        if (selectedValue === 'Review' || selectedValue === 'Upload') {
            LoadClaimProducts();
        }
        if (selectedValue === 'Produkter') {
            loadCorrectedProducts();
        }
    };


    const handleToggleExpanded = (productId) => {
        if (expandedProductId === productId) {
            setExpandedProductId(null); // Collapse the row
        } else {
            setExpandedProductId(productId); // Expand the row
        }
    };

    if (loading) {
        return <div><Loader sizex={78} colors="Sand" /></div>;
    }

    if (error) {
        return <div>Error: {error.message}</div>;
    }

    return (
        <div style={{ paddingBottom: '20px', paddingTop: '40px' }}>
            <div className="thable">
                <div className="Searchbar-and-radio">
                    <RadioButtonsGroup
                        options={['Hent EPD', 'Upload', 'Processing', 'Review', 'Produkter']}
                        selectedValue={radioValue}
                        handleRadioChange={handleRadioChange}
                    />
                </div>
                {radioValue === 'Hent EPD' ? (
                    <div>
                        <SearchEngineID />
                    </div>
                ) : radioValue === 'Upload' ? (
                    <div>
                        {/* Add your component for uploading documents */}
                        <UploadComponent products={products} setProducts={setProducts}  />
                    </div>
                ) : radioValue === 'Processing' ? (
                    <div>
                        {/* Add your processing content */}
                        <ProcessingComponent />
                    </div>
                ) : radioValue === 'Review' || radioValue === 'Produkter' ? (
                    <table>
                        <thead>
                            <tr>
                                <th></th>
                                <th>EPD ID</th>
                                <th>Materialer</th>
                                <th>RSL</th>
                                <th>PCR</th>
                                <th>Massefylde</th>
                                <th>Overholdelse</th>
                                <th>Enheder</th>
                            </tr>
                        </thead>
                        <tbody>
                            {products.map((product) => (
                                <TableRow
                                    key={product.id}
                                    product={product}
                                />
                            ))}
                        </tbody>
                    </table>
                ) : null}
            </div>
        </div>
    );
    }
    


export default UploadTable;