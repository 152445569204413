import React, { useState, useEffect } from 'react';
import { useGlobalContext } from '../GlobalContext';
import { CiSquarePlus } from 'react-icons/ci';
import './styles/ProjektPage.css';
import { NavLink } from 'react-router-dom';
import { getProjects, deleteProject } from '../components/APIS/postGetProject'; // Import getProjects
import { fetchItemData } from '../components/APIS/categoryAPI';
import DeleteProjectOverlay from './Project/projectcomp';
import { MdDeleteForever } from "react-icons/md";
import { CreateProjectModal } from './Project/createproject';
// import MenuProfile from '../components/menuProfile';
function ProjektPage() {
  
    const { selectedProjects, setSelectedProjects } = useGlobalContext();
    const [showCreateProjectModal, setShowCreateProjectModal] = useState(false);

    const fetchProjects = async () => {
        const projects = await getProjects();

        const updatedProjects = await Promise.all(projects.map(async (project) => {
            if (project.items && project.items.length > 0) {
                const updatedItems = await Promise.all(project.items.map(async (item) => {
                    const Ritem = await fetchItemData(item);
                    return { ...item, imageId: Ritem.imageId }; 
                }));

                return { ...project, items: updatedItems }; 
            }
            return project; 
        }));

        setSelectedProjects(updatedProjects);
    };
    useEffect(() => {  
        fetchProjects();
    }); 
    

    
    
    const [showDeleteOverlay, setShowDeleteOverlay] = useState(false);

    const handleDeleteProject = async (id) => {
      
      
      await deleteProject(id);
        await fetchProjects();
    setShowDeleteOverlay(false);
      
    };
    


    return (
        <div className="ProjektPage">
           
            <div className='work-area'>     
            <div className='proj-modal-container'>

                    <div className="proj-modal">
                                <CiSquarePlus 
                                    size={32} 
                                    onClick={() => setShowCreateProjectModal(true)}  
                                    />

                                {showCreateProjectModal && (
                                    <CreateProjectModal 
                                  
                                    showModal={showCreateProjectModal}
                                    onProjectCreated={() => {
                                        setShowCreateProjectModal(false);
                                        fetchProjects();
                                    }}
                                    onClose={() => setShowCreateProjectModal(false)}
                                    />
                                    )}
                                  </div>
                                <div className="proj-modal">

                         <MdDeleteForever size={32} onClick={() => setShowDeleteOverlay(true)}/>
                        {showDeleteOverlay && (
                            <DeleteProjectOverlay 
                            projects={selectedProjects} 
                            onDelete={handleDeleteProject}
                            onClose={() => setShowDeleteOverlay(false)} 
                            />
                            )}
                            </div>
                                </div>
                  

                    <div className={`cont-box-grid`}>
                        {selectedProjects.map((project, index) => (
                            <NavLink key={index} to={`/project/${project.id}`} className="project-item">
                                <h2>{project.ProjectName}</h2>
                                <div className="project-boxes-container">
                                    {project.items.slice(0, 3).map((item, itemIndex) => (
                                        item.imageId && 
                                        <img key={itemIndex} 
                                            className="project-box" 
                                            src={`/images/kategoriimages/${item.imageId}.jpg`} 
                                            alt="Project" />
                                    ))}
                                </div>
                            </NavLink>
                        ))}
                    </div>

            </div>
        </div>
    );
}

export default ProjektPage;


