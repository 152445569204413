import React, { useState, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

// Import category images
import Facade from './Kategori/kategori_billeder/Facade.svg';
import Fundament from './Kategori/kategori_billeder/Fundament.svg';
import Gulv from './Kategori/kategori_billeder/Gulv.svg';
import Inderveg from './Kategori/kategori_billeder/inderveg.svg';
import Isolering from './Kategori/kategori_billeder/Isolering.svg';
import Konstruktion from './Kategori/kategori_billeder/Konstruktion.svg';
import Loft from './Kategori/kategori_billeder/Loft.svg';
import Prefabrikerede_Elementer from './Kategori/kategori_billeder/Prefabrikerede_Elementer.svg';
import Tag from './Kategori/kategori_billeder/Tag.svg';

import RadioButtonsGroup from '../components/radiobuttongroup';
import './styles/KategoriPage.css'; 
import {fetchAll} from '../components/APIS/categoryAPI';

function KategoriPage() {
  const [radioValue, setRadioValue] = useState('Konstruktioner');

  // Hardcoded categories
  const hardcodedCategories = [
    'Facade', 'Fundament', 'Gulv', 'Indervæg', 'Isolering', 
    'Konstruktion', 'Loft', 'Præfabrikerede Elementer', 'Tag'
  ];
  const [subcategories, setSubcategories] = useState([]);
  


  const getImageByCategory = (category) => {
    switch (category.toLowerCase()) {
      case 'facade': return Facade;
      case 'fundament': return Fundament;
      case 'gulv': return Gulv;
      case 'indervæg': return Inderveg;
      case 'isolering': return Isolering;
      case 'konstruktion': return Konstruktion;
      case 'loft': return Loft;
      case 'præfabrikerede elementer': return Prefabrikerede_Elementer;
      case 'tag': return Tag;
      default: return null;
    }
  };

  const navigate = useNavigate();

  const handleBoxClick = (title) => {
    navigate(`/kategori/${title}`, { state: { category: title } });
  };

  const boxes = hardcodedCategories.map((title, index) => (
    <div key={index} className="box" onClick={() => handleBoxClick(title)}>
      <img src={getImageByCategory(title)} className="box-img" alt={title} />
      <h3 className="box-title">{title}</h3> 
    </div>
  ));

  const handleRadioChange = (e) => {
    setRadioValue(e.target.value);
  };

 

    // Material radio button options
  const materials = [
    "Træ og træbaserede plader",
    "Plast og gummi",
    "Andre minerale materialer",
    "Natursten, tagsten, glas, keramik",
    "Maling og overfladebehandling",
    "System",
    "Standardværdier",
    "Rør",
    "Færdige elementer ",
    "Metaller",
    "Andre biogene materialer",
    "Affald",
    "Jordarter, drænmateriale"
  ];

  const materialContent = (
    <div className="material-columns">
      {materials.map((material, index) => (
        <div key={index} className="material-column">
          <p>{material}</p>
        </div>
      ))}
    </div>
  );

  const [inputValue, setInputValue] = useState('');

  function handleInputChange(e) {
    setInputValue(e.target.value);
  }

  function handleButtonClick() {
    alert(inputValue);
  }

  return (
    <div style={{paddingBottom:'20px',paddingTop:'40px'}}>
      <div className="page-kategori-container">
        <div className="logo-container"></div>
        <input
          type="text"
          placeholder="Feature coming soon"
          className="search-input"
          value={inputValue}
          onChange={handleInputChange}
        />
        <button className="search-button" onClick={handleButtonClick}>
          Søg
        </button>
      </div>

      <div className="searchbar-and-radio">
        <RadioButtonsGroup 
          options={['Konstruktioner', 'Materialer']} 
          selectedValue={radioValue} 
          handleRadioChange={handleRadioChange} 
        />
      </div>

      {radioValue === 'Konstruktioner' ? (
        <div style={{paddingTop:'15px'}} className="boxes-container">
          {boxes}
        </div>
      ) : (
        <>
          {materialContent}
        </>
      )}
    </div>
  );
}

export default KategoriPage;