import './tabel.css';

function NumberWithHover({ number }) {
  // Check if the number is defined and is a number
  if (number === null || number === undefined || typeof number !== 'number' || isNaN(number)) {
    return <div className="number-container-filter">Invalid data</div>;
  }

  // Converts the number to scientific notation
  const scientificNumber = number.toExponential(2);

  // Formats the number to a fixed number of decimal places for the tooltip
  const fullNumber = number.toFixed(2);

  return (
    <div className="number-container-filter" title={fullNumber}>
      <div style={{ width: '55px' }}>{scientificNumber}</div> {/* Set a fixed width */}
    </div>
  );
}

  
function MaterialTable({ title, data }) {
    const { categories, rows } = data;
      // Function to check if a value is a number and return NumberWithHover component or plain value
  const renderCell = (cell) => {
    // Parse the cell to a float to check if it's a valid number
    
    const number = parseFloat(cell);
    // Check if the parsed number is a finite number and equal to the cell value (to avoid false positives with strings that can be coerced to numbers, like dates)
    if (!isNaN(number) && isFinite(number) && number.toString() === cell.toString() && title === 'Environmental Impact') {
      return <NumberWithHover number={number} />;
    } else {
      return cell;
    }
  };

    // const descriptions  = [
    //     { acronym: 'GWP', description: 'Global Warming Potential - A measure of how much heat a greenhouse gas traps in the atmosphere up to a specific time horizon, compared to carbon dioxide.' },
    //     { acronym: 'ODP', description: 'Ozone Depletion Potential - Indicates the potential for a substance to destroy the ozone layer compared to R-11 or CFC-11.' },
    //     { acronym: 'POCP', description: 'Photochemical Ozone Creation Potential - Refers to the potential of a chemical to contribute to the formation of ground-level ozone.' },
    //     { acronym: 'AP', description: 'Acidification Potential - Measures the potential for substances to contribute to acid rain.' },
    //     { acronym: 'EP', description: 'Eutrophication Potential - The potential of nutrients to create an overgrowth of algae in water bodies.' },
    //     { acronym: 'ADPE', description: 'Abiotic Depletion Potential for Elements - A measure of the potential for the depletion of non-renewable elements such as minerals and metals.' },
    //     { acronym: 'ADPF', description: 'Abiotic Depletion Potential for Fossil Fuels - Focuses on the depletion of non-renewable fossil fuels.' },
    //     { acronym: 'PERT', description: 'Perturbation - Typically used to refer to a change in the natural state of an ecosystem or environment due to human activity.' },
    //     { acronym: 'PENRT', description: 'Potential Environmental Non-Renewable Transformation - Might refer to the transformation of non-renewable resources affecting the environment.' },
    //     { acronym: 'RSF', description: 'Resource Scarcity Footprint - A measure of the impact of human activity on the availability of natural resources.' },
    //     { acronym: 'NRSF', description: 'Non-Renewable Resource Scarcity Footprint - Focuses on the use and availability of non-renewable resources.' }
    //   ]; // maybe use this later
      
    return (
      <div className="table-container">
        <h2 className="table-title">{title}</h2>
        <div className="material-table">
          <table>
            <thead>
              <tr>
                {categories.map((category, idx) => (
                  <th key={idx}>{category}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              {rows.map((row, i) => (
                <tr key={i}>
                  {row.map((cell, idx) => (
                    <td key={idx}>
                      {renderCell(cell)}
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
            
            <caption 
              className='Captiontable' 
              data-empty={title !== 'Environmental Impact'} 
            >
              {title === 'Environmental Impact' && (
                'The numbers are declared in scientific notation, e.g. 1.95e+02. This number can also be written as: 1.95x10² or 195, while 1.2e-11 is the same as 0.12x10⁻¹¹ or 0.000000000012. Hover a number to see the full value.'            
              )}
            </caption>
          </table>
        </div>
      </div>
    );
  }
  
  export default MaterialTable;
