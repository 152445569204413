import React, { useState, useCallback, useEffect } from 'react';
import { useDropzone } from 'react-dropzone';
import {
  postBrugsBlad,
  postCertification,
  postImage,
  postTechSpec,
  postOther,
  deleteCertification,
  deleteBrugsBlad,
  deleteImage,
  deleteTechSpec,
  deleteOther,
} from '../../../components/APIS/uploadAPI';
import { GrDocumentVerified, GrTrash } from 'react-icons/gr';
import styles from './UploadRow.module.css';
import { fetchItemData } from '../../../components/APIS/categoryAPI';
// Modal Component for Selecting Certifications
const certificationsList = {
  1: 'CE',
  2: 'Svanemarke',
  3: 'Cradle to Cradle',
  4: 'Indeklima Mark',
  5: 'FSC',
  6: 'PEFC',
  7: 'EU Ecolabel',
  8: 'Der Blue Engel',
  9: 'Astma Mark',
  10: 'Allergy UK',
};




const CertificationModal = ({
  show,
  onClose,
  onSave,
  selectedCertifications = {},
  certificationFiles = {},
  productId,
}) => {
  const [localSelectedCerts, setLocalSelectedCerts] = useState(selectedCertifications);
  const [localCertFiles, setLocalCertFiles] = useState(certificationFiles);

  // Synchronize local state with props when they change
  useEffect(() => {
    setLocalSelectedCerts(selectedCertifications);
    setLocalCertFiles(certificationFiles);
  }, [selectedCertifications, certificationFiles]);

  const handleCertChange = (certificationId) => {
    setLocalSelectedCerts((prevSelected) => ({
      ...prevSelected,
      [certificationId]: !prevSelected[certificationId],
    }));
  };

  const handleFileUpload = async (file, certId) => {
    try {
      const response = await postCertification(productId, file, certId);
      console.log(`Certification ${certId} uploaded for product ${productId}`);

      // Update state with the uploaded file and its URL
      setLocalCertFiles((prevFiles) => ({
        ...prevFiles,
        [certId]: {
          file,
          url: response.url, // Assuming response contains the uploaded file URL
        },
      }));
    } catch (error) {
      console.error(`Error uploading certification ${certId} for product ${productId}:`, error);
    }
  };

  // FileDropzone component for drag-and-drop functionality
  const FileDropzone = ({ certId }) => {
    const onDrop = useCallback(
      (acceptedFiles) => {
        if (acceptedFiles && acceptedFiles.length > 0) {
          const file = acceptedFiles[0];
          handleFileUpload(file, certId);
        }
      },
      [certId]
    );

    const { getRootProps, getInputProps, isDragActive } = useDropzone({
      onDrop,
    });

    return (
      <div {...getRootProps()} className={styles.dropzone}>
        <input {...getInputProps()} />
        {isDragActive ? (
          <p>Drop the file here ...</p>
        ) : (
          <p>Drag 'n' drop a file here, or click to select a file</p>
        )}
      </div>
    );
  };

  const handleSave = () => {
    onSave(localSelectedCerts, localCertFiles);
    onClose();
  };

  if (!show) return null;

  return (
    <div className={styles.modalContainer}>
      <div className={styles.modalContent}>
        <h3>Select Certifications and Upload Files</h3>
        {Object.keys(certificationsList).map((certId) => (
          <div key={certId}>
            <label>
              <input
                type="checkbox"
                checked={!!localSelectedCerts[certId]}
                onChange={() => handleCertChange(certId)}
              />
              {certificationsList[certId]}
            </label>
            {localSelectedCerts[certId] && (
              <div>
                <label>Upload {certificationsList[certId]} File:</label>
                {/* Show uploaded file link if exists */}
                {localCertFiles[certId] && localCertFiles[certId].url ? (
                  <div>
                    <a
                      href={localCertFiles[certId].url}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {localCertFiles[certId].file.name}
                    </a>
                  </div>
                ) : (
                  // Use FileDropzone for drag-and-drop
                  <FileDropzone certId={certId} />
                )}
              </div>
            )}
          </div>
        ))}
        <button onClick={handleSave}>Save</button>
        <button onClick={onClose}>Cancel</button>
      </div>
    </div>
  );
};









const ProductRow = ({ product, onFilesChange, onCertificationsChange, setProducts }) => {
  const [files, setFiles] = useState({});
  const [certifications, setCertifications] = useState({});
  const [certificationFiles, setCertificationFiles] = useState({});
  const [modalVisible, setModalVisible] = useState(false);
  const [hoveredFile, setHoveredFile] = useState(null); // Track the hovered file type
  const [deletedFiles, setDeletedFiles] = useState({}); // Track which files are deleted

  // Mapping file types to corresponding delete API functions
  const fileDeleteHandlers = {
    image: async (productId, fileId) => deleteImage(productId, fileId),
    technical_specifications: async (productId) => deleteTechSpec(productId),
    brugsblad: async (productId) => deleteBrugsBlad(productId),
    andre: async (productId, fileId) => deleteOther(productId, fileId),
    certifications: async (productId, certId) => deleteCertification(productId, certId),
  };

  // Handle file upload for individual fields
  const handleFileUpload = async (file, fileType) => {
    // Update state
    const updatedFiles = {
      ...files,
      [fileType]: file,
    };
    setFiles(updatedFiles);
    onFilesChange(product.id, updatedFiles); // Pass file changes to parent

    // Call the appropriate upload function immediately
    try {
      switch (fileType) {
        case 'image':
          await postImage(product.id, file);
          break;
        case 'technical_specifications':
          await postTechSpec(product.id, file);

          break;
        case 'brugsblad':
          await postBrugsBlad(product.id, file);

          break;
        case 'andre':
          await postOther(product.id, file);
          break;
        default:
          console.error(`Unknown file type: ${fileType}`);
      }
      console.log(`File ${fileType} uploaded for product ${product.id}`);
    } catch (error) {
      console.error(`Error uploading ${fileType} for product ${product.id}:`, error);
    }
  };

  // Open the certification modal
  const openCertificationModal = () => {
    setModalVisible(true);
  };
  const closeModal = () => {
    setModalVisible(false);
  };

  // Save certifications
  const saveCertifications = (selectedCerts, certFiles) => {
    setCertifications(selectedCerts);
    setCertificationFiles(certFiles);
    setModalVisible(false);
    onCertificationsChange(product.id, selectedCerts, certFiles); // Pass certifications to parent
  };

  // Handle file deletion and rerender
  const handleDeleteFile = async (fileType, index = 0, certId = null) => {
    try {
      const deleteFunction = fileDeleteHandlers[fileType];
      let fileId = null;

      // Extract fileId for image and andre cases
      if (fileType === 'image' && product.producentUploadData?.imageURLS) {
        fileId = product.producentUploadData.imageURLS[index]?.imageId;
      } else if (fileType === 'andre' && product.producentUploadData?.otherURLS) {
        fileId = product.producentUploadData.otherURLS[index]?.fileId;
      }

      // For certifications, use certId
      if (fileType === 'certifications' && certId) {
        await deleteFunction(product.id, certId);
        // Remove certification from state
        setCertifications((prev) => {
          const updated = { ...prev };
          delete updated[certId];
          return updated;
        });
        setCertificationFiles((prev) => {
          const updated = { ...prev };
          delete updated[certId];
          return updated;
        });
      } else {
        // Call the appropriate delete function
        if (deleteFunction && fileId) {
          await deleteFunction(product.id, fileId); // Pass productId and fileId where needed
        } else if (deleteFunction) {
          await deleteFunction(product.id); // Pass only productId if fileId is not needed
        }

        // After successful delete, update state to hide the link and show input
        setDeletedFiles((prev) => ({
          ...prev,
          [`${fileType}-${index}`]: true, // Mark file as deleted
        }));

        // Optionally, clear the files state to reflect deletion in the parent
        setFiles((prevFiles) => ({
          ...prevFiles,
          [fileType]: null, // Reset file entry
        }));

      }

      console.log(`File ${fileType} deleted for product ${product.id}`);
    } catch (error) {
      console.error(`Error deleting ${fileType} for product ${product.id}:`, error);
    }
  };

  // Render file link or input field depending on data presence
  const renderFileLink = (url, fileType, index, certId = null) => (
    <div
      key={`${fileType}-${index}`}
      className={styles.fileContainer} // Apply CSS module style
      onMouseEnter={() => setHoveredFile(`${fileType}-${index}`)}
      onMouseLeave={() => setHoveredFile(null)}
    >
      <button
        onClick={() => window.open(url, '_blank')} // Open the file in a new tab
        className={styles.fileButton} // Add custom style for button
      >
        View File
      </button>
      {hoveredFile === `${fileType}-${index}` ? (
        <GrTrash
          onClick={() => handleDeleteFile(fileType, index, certId)}
          className={styles.fileIconDelete}
        />
      ) : (
        <GrDocumentVerified className={styles.fileIcon} />
      )}
    </div>
  );

  // FileDropzone component for drag-and-drop functionality
  const FileDropzone = ({ fileType, onFileUpload }) => {
    const onDrop = useCallback(
      (acceptedFiles) => {
        if (acceptedFiles && acceptedFiles.length > 0) {
          const file = acceptedFiles[0];
          onFileUpload(file, fileType);
        }
      },
      [onFileUpload, fileType]
    );

    const { getRootProps, getInputProps, isDragActive } = useDropzone({
      onDrop,
    });

    return (
      <div {...getRootProps()} className={styles.dropzone}>
        <input {...getInputProps()} />
        {isDragActive ? (
          <p> + Upload</p>
        ) : (
          <p> + Upload </p>
        )}
      </div>
    );
  };

  // Helper function to render the correct file field
  const renderFileField = (fileType, urls) => {
    // Check if file is deleted and show input instead of link
    if (deletedFiles[`${fileType}-0`] || !urls || (Array.isArray(urls) && urls.length === 0)) {
      return <FileDropzone fileType={fileType} onFileUpload={handleFileUpload} />;
    }

    // If it's an array of URLs, render multiple file links
    if (fileType === 'image' || fileType === 'andre') {
        urls = urls.map((url) => url.url);
    }
    return Array.isArray(urls)
      ? urls.map((url, index) => renderFileLink(url, fileType, index))
      : renderFileLink(urls, fileType, 0);
  };

  // Render uploaded certifications
  const renderCertifications = () => {
    return Object.keys(certificationFiles).map((certId, index) => (
      <div key={`cert-${certId}`} className={styles.fileContainer}>
        <span>{certificationsList[certId]}</span>
        {certificationFiles[certId].url ? (
          <button
            onClick={() => window.open(certificationFiles[certId].url, '_blank')}
            className={styles.fileButton}
          >
            View File
          </button>
        ) : (
          <span>No file uploaded</span>
        )}
        <GrTrash
          onClick={() => handleDeleteFile('certifications', index, certId)}
          className={styles.fileIconDelete}
        />
      </div>
    ));
  };

  return (
    <>
      <tr>
        <td>{product.productName}</td>

        {/* Image Upload */}
        <td>{renderFileField('image', product.producentUploadData?.imageURLS)}</td>

        {/* Technical Specifications Upload */}
        <td>
          {renderFileField(
            'technical_specifications',
            product.producentUploadData?.tekniskSpecifikationURL
          )}
        </td>

        {/* Brugsblad Upload */}
        <td>
          {renderFileField('brugsblad', product.producentUploadData?.producentBladURL)}
        </td>

        {/* Certifications */}
        <td>
          <button className={styles.certificationButton} onClick={openCertificationModal}>
            Select Certifications
          </button>
          {renderCertifications()}
        </td>

        {/* Other Uploads */}
        <td>{renderFileField('andre', product.producentUploadData?.otherURLS)}</td>
      </tr>
      {modalVisible && (
        <CertificationModal
          show={modalVisible}
          onClose={closeModal}
          onSave={saveCertifications}
          selectedCertifications={certifications}
          certificationFiles={certificationFiles}
          productId={product.id} // Pass productId to modal
        />
      )}
    </>
  );
};




















function UploadComponent({ products, setProducts }) {
  const [allFiles, setAllFiles] = useState({});
  const [allCertifications, setAllCertifications] = useState({});




  // Collect files from each row
  const handleFilesChange = (productId, files) => {
    setAllFiles((prevFiles) => ({
      ...prevFiles,
      [productId]: files,
    }));

  };

  // Collect certifications from each row
  const handleCertificationsChange = (productId, selectedCerts, certFiles) => {
    setAllCertifications((prevCertifications) => ({
      ...prevCertifications,
      [productId]: {
        certifications: selectedCerts,
        files: certFiles,
      },
    }));
  };

  

  return (
    <div>
      <table>
        <thead>
          <tr>
            <th>Navn</th>
            <th>Billede</th>
            <th>Technical Specifications</th>
            <th>Brugsblad</th>
            <th>Certifikationer</th>
            <th>Andre</th>
          </tr>
        </thead>
        <tbody>
          {products.map((product) => (
            <ProductRow
              key={product.id}
              product={product}
              onFilesChange={handleFilesChange}
              onCertificationsChange={handleCertificationsChange}
              setProducts={setProducts}
            />
          ))}
        </tbody>
      </table>
    </div>
  );
}

export default UploadComponent;
