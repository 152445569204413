import React, { useState } from 'react';
import { baseUrl, useGlobalContext } from "../GlobalContext";
import { NavLink, useNavigate } from 'react-router-dom';
import './styles/LoginPage.css'; 

function LoginPage() {
    const [email, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const navigate = useNavigate();
    const [loginError, setLoginError] = useState(false);
    const { userType, setUserType } = useGlobalContext();
    
    const setUserData = async(e) => {
        try {
            const response = await fetch(`${baseUrl}/User`, {
                method: 'Get',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('sessionToken')}`
                },
            });
            const data = await response.json();
            if (response.ok){
                setUserType(data);
                console.log(data)

            }
            else {
                console.error(data.error);
            }

        } catch(error) {

            console.error(error);

        }



    }

    const handleLogin = async (e) => {
        e.preventDefault(); // Prevents the default form submission behavior
        setLoginError(false);

        try {
            const response = await fetch(`${baseUrl}/Auth`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    
                },
                body: JSON.stringify({ email: email, password: password }),
            });

            const data = await response.json();
            if (response.ok) {
                localStorage.setItem('sessionToken', data.sessionToken);
                navigate('/');
                await setUserData();
                setLoginError(false);
                
            } else {
                console.error(data.error);
                setLoginError(true);
            }
        } catch (error) {
            console.error('Failed to login:', error);
            setLoginError(true);
        }
    };

    return (
        <div className="page-style">
            <div className="login-cont">
                <div className="log-input-style">
                    <form onSubmit={handleLogin}>
                        <h3 className="font-style">Email</h3>
                        <input
                            type="text"
                            name="email"
                            placeholder="Email"
                            value={email}
                            onChange={(e) => {
                                setUsername(e.target.value);
                                setLoginError(false);
                            }}
                            className="input-style"
                        />
                        <h3 className="font-style">Password</h3>
                        <input
                            type="password"
                            name="password"
                            placeholder="Password"
                            value={password}
                            onChange={(e) => {
                                setPassword(e.target.value);
                                setLoginError(false);
                            }}
                            className="input-style"
                        />
                        <div className="login-cont">
                            <button type="submit" className="button-style">Login</button>
                            <p className="register-link">
                                Har du ikke en bruger? <NavLink to="/register">Registrer dig her</NavLink>
                            </p>
                        </div>
                        {loginError && <p className="login-error">Forkert email eller password</p>}
                    </form>
                </div>
            </div>
        </div>
    );
}

export default LoginPage;
