import React, { useState, useEffect } from 'react';
import FileUploader from '../IndexedDB'; // Assuming the path is correct
import { getManifest } from '../../../components/APIS/indexedDBUtils';
import { deleteFileFromProduct, getFilesByProductId, addOrUpdateCategory, createProduct } from '../../../components/APIS/indexedDBUtils';
import { fetchAllCategories } from '../../../components/APIS/categoryAPI';
import { ImCheckboxChecked, ImCheckboxUnchecked } from 'react-icons/im';
import Loader from '../../../components/Utils/Loader';
import { AiOutlineEdit } from 'react-icons/ai';
import { NavLink } from 'react-router-dom';


function TableRow(data) {
    const [isExpanded, setIsExpanded] = useState(false);
    const [files, setFiles] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [manifesto, setManifest] = useState([]);

    useEffect(() => {
        if (!data.product || !data.product.id) {
            setError('Invalid product ID');
            setLoading(false);
            return;
        }
        getManifest(data.product.id)
            .then(manifest => {
                if (manifest && manifest.files) {
                    setManifest(manifest);
                    setFiles(manifest.files);
                } else {

                    createProduct(data.product.id)
                        .then(() => {
                            console.log('Product created successfully');
                        }
                        )
                        .catch(err => {
                            console.error('Failed to create product:', err);
                        }
                        );
                }
                setLoading(false);
            })
            .catch(err => {
                setError(err.message);
                setLoading(false);
            });
    }, [files]);

    return (
        <tr>
            <NavLink to={ `/produkter/correct/${data.product.id}`} state={{data}}  ><AiOutlineEdit style={{ fontSize: "1.5em" }} /></NavLink>
            <td>{data.product.epdid}</td>
            <td>{data.product.materials.map((materials) => <div>{materials.materialName}</div>)}</td>
            <td>{data.product.rsl.map((rsl) => <div>{rsl.rslData}</div>)}</td>
            <td>{data.product.pcr.map((pcr) => <div>{pcr}</div>)}</td>
            <td>{data.product.masseFylde.length}</td>
            <td>{data.product.compliance.map((compliance) => <div>{compliance}</div>)}</td>
            <td>{data.product.declaredUnits.map((declaredUnits) => <div>{declaredUnits.unit}</div>)}</td>
        </tr>
    );
}


export default TableRow;


