import { useParams, NavLink } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { fetchItemData } from '../../components/APIS/categoryAPI';
import { getOneProjectbyId } from '../../components/APIS/postGetProject';
import './ProjectDetails.css';
import { IoReturnUpBack } from "react-icons/io5";
import DetailBox from '../Kategori/DetailBox';

function ProjectDetails() {
    const { projectId } = useParams();
    const [project, setProject] = useState(null);
    const [typeFilter, setTypeFilter] = useState('all');

    // Refreshes the project data
    
    const refreshProject = async () => {
        try {
            const projectData = await getOneProjectbyId(projectId);
            const itemDetailsPromises = projectData.productIds.map(itemId => fetchItemData(itemId));
            const itemsDetails = await Promise.all(itemDetailsPromises);
            const itemsWithDetails = itemsDetails.map((details, index) => ({
                id: projectData.productIds[index],
                data: details
            }));
            setProject({ ...projectData, items: itemsWithDetails });
        } catch (error) {
            console.error(`Error fetching project with ID: ${projectId}`, error);
        }
    };

    // Fetch project data on component mount or when projectId changes
    useEffect(() => {
        refreshProject();
    });

    // Function to get the content based on the project and type filter
    const getContent = () => {
        if (!project) {
            return <div style={{ color: "black" }}>Project not found</div>;
        }
        if (project.items.length === 0) {
            return (
                <div>
                    <p style={{ color: "black" }}>
                        Project has no items, find items on <NavLink to="/kategori">category page</NavLink>
                    </p>
                </div>
            );
        }

        const filteredItems = project.items.filter(item => {
            if (typeFilter === 'null') return item.data.type === null;
            if (typeFilter === 'branch') return item.data.type === 'Branche data';
            return true; // typeFilter === 'all'
        });

        const itemsByCategory = filteredItems.reduce((acc, item) => {
            const category = item.data.category;
            if (!acc[category]) acc[category] = [];
            acc[category].push(item);
            return acc;
        }, {});

        return Object.entries(itemsByCategory).map(([category, items]) => (
            <div key={category} className="category-group">
                <h2 style={{ color: "var(--Green)" }}>{category}</h2>
                {items.map((item, itemIndex) => (
                    <DetailBox
                        handledel={refreshProject}
                        key={itemIndex}
                        itemid={item.id}
                        id={projectId}
                        Project={true}
                        title={item.data.itemTitle}
                        data={item.data}
                        billede_id={item.data.imageId}
                    />
                ))}
            </div>
        ));
    };

    return (
        <div className='Proj-cont'>
            <NavLink to="/projekter" className="Proj-back"><IoReturnUpBack size={34} color='#334C31' /></NavLink>
            <h1 className='h-proj'>{project?.projectName}</h1>
  
            {/* Radio buttons for type filter */}
            <div>
                <label>
                    <input
                        type="radio"
                        value="all"
                        checked={typeFilter === 'all'}
                        onChange={() => setTypeFilter('all')}
                    />
                    All
                </label>
                <label>
                    <input
                        type="radio"
                        value="null"
                        checked={typeFilter === 'null'}
                        onChange={() => setTypeFilter('null')}
                    />
                    Product
                </label>
                <label>
                    <input
                        type="radio"
                        value="branch"
                        checked={typeFilter === 'branch'}
                        onChange={() => setTypeFilter('branch')}
                    />
                    Branche
                </label>
            </div>
  
            {getContent()}
        </div>
    );
}

export default ProjectDetails;
