
import { baseUrl } from "../../GlobalContext";


export const fetchEPDid = async (id) => {
        try {
          const response = await fetch(`${baseUrl}/Producent/getProduct?EPDID=${id}`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${localStorage.getItem('sessionToken')}`
            }
          });
          

          if (response.status === 404) {
            throw new Error(`Product not found`);
          }
          else if (response.status === 401) {
            throw new Error(`Unauthorized`);
          }
          else if (response.status === 403) {
            throw new Error(`Forbidden`);
          }
          else if (!response.ok) {
            throw new Error(`Server responded with ${response.status}: ${response.statusText}`);
          }
          if (response.ok) {
            const data = await response.json();
            return data;
          }
        } catch (error) {
          console.error('fetchEPDid:', error);
          return null;
        }
      }

      export const claimEPDid = async (b, idArray) => {
        try {
          // Ensure idArray is an array
          if (!Array.isArray(idArray)) {
            throw new Error('The id parameter must be an array.');
          }
      
          // Prepare the request body as a JSON array string
          const body = JSON.stringify(idArray);
      
          console.log(body);
          console.log(`${baseUrl}/Producent/ClaimProduct?troOgLove=${b}`);
      
          const response = await fetch(`${baseUrl}/Producent/ClaimProduct?troOgLove=${b}`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${localStorage.getItem('sessionToken')}`
            },
            body: body  // Send the array directly as the request body
          });
      
          // Check if the request was successful
          if (!response.ok) {
            throw new Error(`Error: ${response.statusText}`);
          }
      
          // Parse the JSON response
          const data = await response.json();
          return data;
      
        } catch (error) {
          console.error('There was an error claiming the EPD ID:', error);
          throw error;
        }
      };
      
      export const getClaimedEPD = async () => {
        try {
          const response = await fetch(`${baseUrl}/Producent/GetClaimedProducts`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'authorization': `Bearer ${localStorage.getItem('sessionToken')}`
            }
          });
          if (!response.ok) {
            throw new Error(`Error: ${response.statusText}`);
          } else {
            const data = await response.json();
            return data;
          }

        } catch (error) {
          console.error('There was an error getting claimed EPD:', error);
          throw error;
        }


      };


      export const GetCorrectedEPD = async () => {
        try {
          const response = await fetch (`${baseUrl}/Producent/GetCorrectedProducts`, {
            method: 'Get',            
            headers: {
              'Content-Type': 'application/json',
              'authorization': `Bearer ${localStorage.getItem('sessionToken')}`
            }
          });
          if (!response.ok)
          {
            throw new Error(`Error: ${response.statusText}`);
          } 
          else 
          {
            const data = await response.json();
            return data;
          }
        }
        catch (error)
        {
          console.log('There was an error getting corrected EPD:', error);
          throw error;
        }
      };

      export const CorrectProduct = async (ProductCorrectionRequest) => {
        const headers = {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('sessionToken')}`
        };
        const body = JSON.stringify(ProductCorrectionRequest);
        console.log(body);
        try {
        const response = await fetch (`${baseUrl}/Producent/CorrectManualData`, {
          method: 'POST',
          headers: headers,
          body: body
        });
        if (!response.ok) {
          throw new Error(`Error: ${response.statusText}`);
        } else {
          const data = await response.json();
          return data;
        }
    
      } catch (error) {
        console.error('There was an error correcting the product:', error);
        throw error;
      }
      };






// POST
// /Pdf/technical-specification
// /Pdf/certification
// /Pdf/image
// /Pdf/BrugsBlad
// /Pdf/Other
// /Pdf/ProcessStatus

      export const postTechSpec = async (productId, file) => {
        const headers = {
          'Authorization': `Bearer ${localStorage.getItem('sessionToken')}`,
        };
      
        // Create FormData and append file and productId
        const formData = new FormData();
        formData.append('file', file);
      
        try {
          const response = await fetch(`${baseUrl}/Pdf/technical-specification?productId=${productId}`, {
            method: 'POST',
            headers: headers, // No 'Content-Type', browser will set it automatically for FormData
            body: formData, // Pass the formData object directly
          });
      
          if (!response.ok) {
            throw new Error(`Error: ${response.statusText}`);
          } else {
            const data = await response.json();
            return data;
          }
        } catch (error) {
          console.error('There was an error uploading the technical specification:', error);
          throw error;
        }
      };

  export const postCertification = async (productId, file, type) => {
    const headers = {
          'Authorization': `Bearer ${localStorage.getItem('sessionToken')}`,
        };

        // Create FormData and append file and productId
        const formData = new FormData();
        formData.append('file', file);

        try {
          const response = await fetch(`${baseUrl}/Pdf/certification?productId=${productId}&certificationType=${type}`, {
            method: 'POST',
            headers: headers, // No 'Content-Type', browser will set it automatically for FormData
            body: formData, // Pass the formData object directly
          });

          if (!response.ok) {
            throw new Error(`Error: ${response.statusText}`);
          } else {
            const data = await response.json();
            return data;
          }
        }

        catch (error) {
          console.error('There was an error uploading the certification:', error);
          throw error;
        }
  }


  export const postImage = async (productId, file) => {
    const headers = {
        'Authorization': `Bearer ${localStorage.getItem('sessionToken')}`, // Authorization header
    };

    // Create FormData and append the file with the key 'file'
    const formData = new FormData();
    formData.append('file', file); // 'file' key matches what the backend expects

    try {
        // Make the POST request with FormData as the body
        const response = await fetch(`${baseUrl}/Pdf/image?productId=${productId}`, {
            method: 'POST',
            headers: headers, // Only the Authorization header, FormData sets 'Content-Type' automatically
            body: formData,   // FormData is passed directly, no manual Content-Type needed
        });

        // Check for successful response
        if (!response.ok) {
            throw new Error(`Error: ${response.statusText}`);
        }

        // Parse and return the response data (assumed to be JSON)
        const data = await response.json();
        return data;
    } catch (error) {
        console.error('There was an error uploading the image:', error);
        throw error;
    }
};

  export const postBrugsBlad = async (productId, file) => {
    const headers = {
          'Authorization': `Bearer ${localStorage.getItem('sessionToken')}`,
        };

        // Create FormData and append file and productId
        const formData = new FormData();
        formData.append('file', file);

        try {
          const response = await fetch(`${baseUrl}/Pdf/BrugsBlad?productId=${productId}`, {
            method: 'POST',
            headers: headers, // No 'Content-Type', browser will set it automatically for FormData
            body: formData, // Pass the formData object directly
          });

          if (!response.ok) {
            throw new Error(`Error: ${response.statusText}`);
          } else {
            const data = await response.json();
            return data;
          }
        }

        catch (error) {
          console.error('There was an error uploading the brugsblad:', error);
          throw error;
        }


  }

  export const postOther = async (productId, file) => {
    const headers = {
          'Authorization': `Bearer ${localStorage.getItem('sessionToken')}`,
        };

        // Create FormData and append file and productId
        const formData = new FormData();
        formData.append('file', file);

        try {
          const response = await fetch(`${baseUrl}/Pdf/Other?productId=${productId}`, {
            method: 'POST',
            headers: headers, // No 'Content-Type', browser will set it automatically for FormData
            body: formData, // Pass the formData object directly
          });

          if (!response.ok) {
            throw new Error(`Error: ${response.statusText}`);
          } else {
            const data = await response.json();
            return data;
          }
        }

        catch (error) {
          console.error('There was an error uploading the other:', error);
          throw error;
        }
  }

  export const getProcessStatus = async (productId) => {
    const headers = { 
        'Authorization': `Bearer ${localStorage.getItem('sessionToken')}`,
    };

    try {
        const response = await fetch(`${baseUrl}/Pdf/ProcessStatus?productId=${productId}`, {
            method: 'GET',
            headers: headers,
        });

        if (!response.ok) {
            throw new Error(`Error: ${response.statusText}`);
        } else {
            const data = await response.json();
            return data;
        }
    }

    catch (error) {
        console.error('There was an error getting the process status:', error);
        throw error;
    }

  }


 export const deleteTechSpec = async (productId) => {
    const headers = {
      'Authorization': `Bearer ${localStorage.getItem('sessionToken')}`,
    };

    try { 
      const response = await fetch(`${baseUrl}/Pdf/technical-specification?productId=${productId}`, {
        method: 'DELETE',
        headers: headers,
      });

      if (!response.ok) {
        throw new Error(`Error: ${response.statusText}`);
      } else {
        const data = await response.json();
        return data;
      }
    }
      
      catch (error) {
        console.error('There was an error deleting the technical specification:', error);
        throw error;
      }
  }

  export const deleteCertification = async (productId, type) => {
    const headers = {
      'Authorization': `Bearer ${localStorage.getItem('sessionToken')}`,
    };

    try {
      const response = await fetch(`${baseUrl}/Pdf/certification?productId=${productId}&certificationType=${type}`, {
        method: 'DELETE',
        headers: headers,
      });

      if (!response.ok) {
        throw new Error(`Error: ${response.statusText}`);
      } else {
        const data = await response.json();
        return data;
      }
    }

    catch (error) {
      console.error('There was an error deleting the certification:', error);
      throw error;
    }

  }

  export const deleteImage = async (productId, imageid) => {
    const headers = {
      'Authorization': `Bearer ${localStorage.getItem('sessionToken')}`,
    };

    try {
      const response = await fetch(`${baseUrl}/Pdf/image?productId=${productId}&imageId=${imageid}`, {
        method: 'DELETE',
        headers: headers,
      });

      if (!response.ok) {
        throw new Error(`Error: ${response.statusText}`);
      } else {
        const data = await response.json();
        return data;
      }
    }
      
      catch (error) {
        console.error('There was an error deleting the image:', error);
        throw error;
      }
  }

  export const deleteBrugsBlad = async (productId) => {
    const headers = {
      'Authorization': `Bearer ${localStorage.getItem('sessionToken')}`,
    };

    try {
      const response = await fetch(`${baseUrl}/Pdf/BrugsBlad?productId=${productId}`, {
        method: 'DELETE',
        headers: headers,
      });

      if (!response.ok) {
        throw new Error(`Error: ${response.statusText}`);
      } else {
        const data = await response.json();
        return data;
      }
    }
        
        catch (error) {
          console.error('There was an error deleting the brugsblad:', error);
          throw error;
        }

  }

  export const deleteOther = async (productId, fileid) => {
    const headers = {
      'Authorization': `Bearer ${localStorage.getItem('sessionToken')}`,
    };

    try {
      const response = await fetch(`${baseUrl}/Pdf/Other?productId=${productId}`, {
        method: 'DELETE',
        headers: headers,
      });

      if (!response.ok) {
        throw new Error(`Error: ${response.statusText}`);
      } else {
        const data = await response.json();
        return data;
      }
    }
          
          catch (error) {
            console.error('There was an error deleting the other:', error);
            throw error;
          }
  }











