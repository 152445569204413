import React from 'react';
import DetailBox from './DetailBox';

const MemoizedDetailBoxes = React.memo(({ isLoading, products, id }) => {
  const renderDetailBoxes = () => {
    if (isLoading) {
      return <p>Loading...</p>;
    }
    // Map over the products array to render a DetailBox for each product
    return products.map((product) => (
      <DetailBox
        key={`${product.productName}-${product.subCategory}`}  
        itemId={product.id}
        data={product}
        MainCategory={id}
        SubCategoryAndId={product.subCategory}
        billede_id={product.imageId}
      />
    ));
  };

  return (
    <div>
      {renderDetailBoxes()}
    </div>
  );
});

export default MemoizedDetailBoxes;
