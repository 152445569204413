import FilterCategory from "./cmp/filterCategory";
import styles from "./filter.module.css";
import { useEffect, useState, useCallback } from "react";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import { NumberWithHover } from "./cmp/cmpNHover";
import { fetchDatafromQuery } from "../../../components/APIS/categoryAPI";

function FilterQuery({ setQuery, data,setProducts,pageN, pageS,setMaxPage}) {
  const [activeFilterCategory, setActiveFilterCategory] = useState(null);
  const navigate = useNavigate();
  const location = useLocation();
  const { id } = useParams();
  const [maxHeight, setMaxHeight] = useState({
    GWP: "0",
    Standard: "0",
    Producent: "0",
    "Gyldig til": "0",
    "Brandklasse": "0", // Added 'Brandklasse' (Fire Rating) category
  });
  const [filters, setFilters] = useState({
    compliances: [],
    producers: [],
    brandValue: [], // Added 'brandValue' to filters
    validTo: "",
    gwpValues: {
      a1A3: 0,
      a5: 0,
      b3: 0,
      c1: 0,
      c2: 0,
      c3: 0,
      c4: 0,
      d: 0,
    },
  });
  const renderResetButton = () => {
    return (
      <button className={styles.resetbutton} onClick={resetFilters}>
        Reset
      </button>
    );
  };

  const resetFilters = () => {
    setActiveFilterCategory(null);
    // Reset filters to initial state
    setFilters({
      compliances: [],
      producers: [],
      brandValue: [],
      validTo: data.validTo || 0,
      certifications: [],
      gwpValues: {
          a1A3: data.gwpvalues?.a1A3Max || 0,
          a5: data.gwpvalues?.a5Max || 0,
          b3: data.gwpvalues?.b3Max || 0,
          c1: data.gwpvalues?.c1Max || 0,
          c2: data.gwpvalues?.c2Max || 0,
          c3: data.gwpvalues?.c3Max || 0,
          c4: data.gwpvalues?.c4Max || 0,
          d: data.gwpvalues?.dMax || 0,
      },
  });
  };

  useEffect(() => {
    if (!data || typeof data !== "object") return;

    setFilters({
        compliances: [],
        producers: [],
        brandValue: [],
        validTo: data.validTo || 0,
        certifications: [],
        gwpValues: {
            a1A3: data.gwpvalues?.a1A3Max || 0,
            a5: data.gwpvalues?.a5Max || 0,
            b3: data.gwpvalues?.b3Max || 0,
            c1: data.gwpvalues?.c1Max || 0,
            c2: data.gwpvalues?.c2Max || 0,
            c3: data.gwpvalues?.c3Max || 0,
            c4: data.gwpvalues?.c4Max || 0,
            d: data.gwpvalues?.dMax || 0,
        },
    });
}, []); // Run only when `data` changes

  const handleFilterCategoryClick = (category) => {
    const newMaxHeight = activeFilterCategory === category ? "0" : "2000px";
    setActiveFilterCategory(activeFilterCategory === category ? null : category);
    setMaxHeight({ ...maxHeight, [category]: newMaxHeight });
  };

  const debounce = (func, delay) => {
    let timeout;
    return function (...args) {
      const context = this;
      if (timeout) clearTimeout(timeout);
      timeout = setTimeout(() => func.apply(context, args), delay);
    };
  };

  
  const handleGWPFilter = () => {
    // Extract min and max values directly from data.gwpvalues
    const minMaxData = {};

    if (data && data.gwpvalues) {
        const gwp = data.gwpvalues;

        Object.entries(gwp).forEach(([key, value]) => {
            if (value == null) return;

            const baseKey = key.replace(/Min|Max$/, ""); // Remove 'Min' or 'Max' suffix to get the base key

            // Initialize the min and max objects only if they haven't been set
            if (!minMaxData[baseKey]) {
                minMaxData[baseKey] = { min: null, max: null };
            }

            // Directly set the min and max values as they are already provided
            if (key.endsWith("Min")) {
                minMaxData[baseKey].min = value;
            } else if (key.endsWith("Max")) {
                minMaxData[baseKey].max = value;
            }
        });
    }



    // Handle slider change
    const handleSliderChange = (subCategory, newValue) => {
        setFilters((prevFilters) => ({
            ...prevFilters,
            gwpValues: {
                ...prevFilters.gwpValues,
                [subCategory]: parseFloat(newValue),
            },
        }));
    };

    return (
        <>
            {Object.entries(minMaxData).map(([key, { min, max }]) => {
                // Handle the edge case where min and max are the same
                const isSameMinMax = min === max;
                const adjustedMin = isSameMinMax ? min - 1 : min;
                const adjustedMax = isSameMinMax ? max + 1 : max;

                return (
                    <div key={key} className={styles.gwpslidercontainer} style={{ maxHeight: maxHeight.GWP }}>
                        <label style={{ minWidth: "45px" }} htmlFor={key}>
                            {key.toUpperCase()}
                        </label>
                        <input
                            className={styles.sliderstyle}
                            type="range"
                            id={key}
                            min={adjustedMin || 0}  // Ensure min is not null
                            max={adjustedMax || 100}  // Ensure max is not null
                            step={0.01}
                            value={filters.gwpValues[key] || 0} // Use the current filter value for the slider
                            onChange={(e) => handleSliderChange(key, e.target.value)} // Update the filter state on change
                            disabled={isSameMinMax} // Disable slider if min and max are the same
                        />
                        <NumberWithHover number={filters.gwpValues[key]} />
                    </div>
                );
            })}
        </>
    );
};





  const handleComplianceFilter = () => {
    // Function to handle changes in compliance selection
    const handleComplianceChange = (compliance) => {
      setFilters((prevFilters) => ({
        ...prevFilters,
        compliances: prevFilters.compliances.includes(compliance)
          ? prevFilters.compliances.filter((c) => c !== compliance)
          : [...prevFilters.compliances, compliance],
      }));
    };
  
    // Check if data is valid and has compliances
    if (!data || !data.compliances) {
      return null;
    }
  
    // Extract compliances directly from the data object
    const compliancesArray = Array.from(new Set(data.compliances));
  
    return (
      <div style={{ maxHeight: maxHeight.Standard }}>
        {compliancesArray.map((compliance) => (
          <div key={compliance} className={styles.compliancecheckbox}>
            <input
              type="checkbox"
              id={compliance}
              name={compliance}
              checked={filters.compliances.includes(compliance)}
              onChange={() => handleComplianceChange(compliance)}
            />
            <label htmlFor={compliance}>{compliance}</label>
          </div>
        ))}
      </div>
    );
  };
  

  const handleProducerFilter = () => {
    // Function to handle changes in producer selection
    const handleProducerChange = (producer) => {
      setFilters((prevFilters) => ({
        ...prevFilters,
        producers: prevFilters.producers.includes(producer)
          ? prevFilters.producers.filter((p) => p !== producer)
          : [...prevFilters.producers, producer],
      }));
    };
  
    // Check if data is valid and has producers
    if (!data || !data.producers) {
      return null;
    }
  
    // Extract producers directly from the data object and ensure uniqueness
    const producersArray = Array.from(new Set(data.producers));
  
    return (
      <div style={{ maxHeight: maxHeight.Producent }}>
        {producersArray.map((producer) => (
          <div key={producer} className={styles.producercheckbox}>
            <input
              type="checkbox"
              id={producer}
              name={producer}
              checked={filters.producers.includes(producer)}
              onChange={() => handleProducerChange(producer)}
            />
            <label htmlFor={producer}>{producer}</label>
          </div>
        ))}
      </div>
    );
  };
  

  const handleFireRatingFilter = () => {
    const handleFireRatingChange = (fireRating) => {
      setFilters((prevFilters) => ({
        ...prevFilters,
        brandValue: prevFilters.brandValue.includes(fireRating)
          ? prevFilters.brandValue.filter((f) => f !== fireRating)
          : [...prevFilters.brandValue, fireRating],
      }));
    };


    if (data && !data.brandValue) {
      return null;
    }

    const availableFireRatings = Array.from(new Set(data.brandValue));

    return (
      <div style={{ maxHeight: maxHeight.Brandklasse }}>
        {availableFireRatings.map((fireRating) => (
          <div key={fireRating} className={styles.fireRatingCheckbox}>
            <input
              type="checkbox"
              id={fireRating}
              name={fireRating}
              checked={filters.brandValue.includes(fireRating)}
              onChange={() => handleFireRatingChange(fireRating)}
            />
            <label htmlFor={fireRating}>{fireRating}</label>
          </div>
        ))}
      </div>
    );
  };

  const handleValidToFilter = () => {
    const currentYear = new Date().getFullYear(); // Get the current year
  
    const handleValidToChange = (validTo) => {
      setFilters((prevFilters) => ({
        ...prevFilters,
        validTo: parseInt(validTo, 10), // Convert to an integer
      }));
    };
  
    return (
      <div style={{ maxHeight: maxHeight["Gyldig til"] }}>
        <div style={{ display: "flex", flexDirection: "column" }}>
          <div>
            <label htmlFor="validTo">Gyldig til: </label>
            <span>{filters.validTo || currentYear}</span> {/* Display the selected year or the current year by default */}
          </div>
          <input
            type="range"
            id="validTo"
            min={currentYear - 15} // Set the minimum range to 15 years before the current year
            max={currentYear + 15} // Set the maximum range to 15 years after the current year
            step={1}
            value={filters.validTo || currentYear} // Default to the current year if not set
            onChange={(e) => handleValidToChange(e.target.value)}
          />
        </div>
      </div>
    );
  };
  
  
  const handleCertificationFilter = () => {
    // Function to handle changes in certification selection
    const handleCertificationChange = (certification) => {
      setFilters((prevFilters) => ({
        ...prevFilters,
        certifications: prevFilters.certifications.includes(certification)
          ? prevFilters.certifications.filter((c) => c !== certification)
          : [...prevFilters.certifications, certification],
      }));
    };
  
    // Check if data is valid and has certifications
    if (!data || !data.certifications) {
      return null;
    }
  
    // Extract unique certifications directly from the data object
    const certificationsArray = Array.from(new Set(data.certifications));
  
    return (
      <div style={{ maxHeight: maxHeight.Certification }}>
        {certificationsArray.map((certification) => (
          <div key={certification} className={styles.certificationcheckbox}>
            <input
              type="checkbox"
              id={`certification-${certification}`}
              name={`certification-${certification}`}
              checked={filters.certifications?.includes(certification) || false} // Use optional chaining and provide a default value
              onChange={() => handleCertificationChange(certification)}
            />
            <label htmlFor={`certification-${certification}`}>{certification}</label>
          </div>
        ))}
      </div>
    );
  };
  


  const renderFilters = (cate) => {
    switch (cate) {
      case "GWP":
        return handleGWPFilter();
      case "Gyldig til":
        return handleValidToFilter();
      case "Producent":
        return handleProducerFilter();
      case "Standard":
        return handleComplianceFilter();
      case "Brandklasse": 
        return handleFireRatingFilter();
      case "Certifikationer":
        return handleCertificationFilter();
      default:
        return null;
    }
  };

  const renderFilterCategories = () => {
    let filterCategories = ["GWP", "Standard", "Producent", "Gyldig til","Certifikationer", "Brandklasse"];
    return filterCategories.map((category) => (
      <FilterCategory
        key={category}
        categoryName={category}
        isActive={activeFilterCategory === category}
        onClickHandler={handleFilterCategoryClick}
      >
        {renderFilters(category)}
      </FilterCategory>
    ));
  };
  const fetchCarddata = async (query) => {
    try {
      const data = await fetchDatafromQuery(query);
      setProducts(data.data);
      setMaxPage(data.maxPageNumber);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
    }
  };

  

  const debouncedSetQuery = useCallback(
    debounce((newFilters) => {
      // Extract maximum values for GWP from the data object
      const gwp = data.gwpvalues || {};
      const maxGwpValues = {
        a1A3: gwp.a1A3Max ?? 0,
        a5: gwp.a5Max ?? 0,
        b3: gwp.b3Max ?? 0,
        c1: gwp.c1Max ?? 0,
        c2: gwp.c2Max ?? 0,
        c3: gwp.c3Max ?? 0,
        c4: gwp.c4Max ?? 0,
        d: gwp.dMax ?? 0,
      };
  
      // Extract all possible values for arrays (compliances, producers, fireRatings, certifications)
      const allCompliances = Array.from(new Set(data.compliances || []));
      const allProducers = Array.from(new Set(data.producers || []));
      const allFireRatings = Array.from(new Set(data.brandValue || []));
      const allCertifications = Array.from(new Set(data.certifications || []));
  
      const maxValidTo = data.validTo ?? 0;
  
      const newQuery = {
        pageSize: pageS || 10,
        pageNumber: pageN || 1,
        compliances: newFilters.compliances?.length > 0 ? newFilters.compliances : [],
        producers: newFilters.producers?.length > 0 ? newFilters.producers : [],
        brandKlasseUnits: newFilters.brandValue?.length > 0 ? newFilters.brandValue : [],
        certifications: newFilters.certifications?.length > 0 ? newFilters.certifications : [],
        validTo: newFilters.validTo || maxValidTo,
        strings: [],
        category: data.category,
        unit: data.unit,
        a1A3: newFilters.gwpValues?.a1A3 || maxGwpValues.a1A3,
        a5: newFilters.gwpValues?.a5 || maxGwpValues.a5,
        b3: newFilters.gwpValues?.b3 || maxGwpValues.b3,
        c1: newFilters.gwpValues?.c1 || maxGwpValues.c1,
        c2: newFilters.gwpValues?.c2 || maxGwpValues.c2,
        c3: newFilters.gwpValues?.c3 || maxGwpValues.c3,
        c4: newFilters.gwpValues?.c4 || maxGwpValues.c4,
        d: newFilters.gwpValues?.d || maxGwpValues.d,
      };
  
      // Update the query state
      fetchCarddata(newQuery);
      setQuery(newQuery);
      }, 250),
    [data,pageS, filters, pageN]
  );
  




// useEffect to call the debouncedSetQuery whenever filters change
useEffect(() => {
  debouncedSetQuery(filters);

}, [filters]);

  return (
    <div className={styles.filter}>
      {renderResetButton()}
      {renderFilterCategories()}
    </div>
  );
}

export default FilterQuery;
