import { baseUrl } from "../GlobalContext";
import { useEffect, useState } from 'react';
import { Navigate } from 'react-router-dom';


export const ProtectedRoute = ({ element: Component, ...rest }) => {
    const [isAuthenticated, setIsAuthenticated] = useState(null);
  
    useEffect(() => {
      const verifyAuth = async () => {
        try {
          const response = await fetch(`${baseUrl}/user`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${localStorage.getItem('sessionToken')}`
            }
          });
          setIsAuthenticated(response.ok);
        } catch (error) {
          console.error(`Error fetching user auth status:`, error);
          setIsAuthenticated(false);
        }
      };
      verifyAuth();
    }, []);
  
    if (isAuthenticated === null) {
      return <div>Loading...</div>; 
    }
  
    return isAuthenticated ? <Component {...rest} /> : <Navigate to="/login" />;
  };
  