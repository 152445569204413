import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { fetchEPDid, claimEPDid, getClaimedEPD } from '../../../components/APIS/uploadAPI';
import { GrSearch } from "react-icons/gr";
import { MdDeleteForever } from 'react-icons/md';
import { ImCheckboxChecked, ImCheckboxUnchecked } from 'react-icons/im';



function SearchEngineID() {
    const [EPDID, setEPDID] = useState('');
    const [EPDDATA, setEPDDATA] = useState([]);
    const [handle, setHandle] = useState(false);
    const [Accepted, setAccepted] = useState([]);
    const [Tol, setTol] = useState(false);
    const [openModal, setOpenModal] = useState(false);


    const handleInput = (input) => {
        // remove blank spaces in start and end
        input = input.trim();
        // console.log(input.split(',').join('&EPDID='));
        return input.split(',').join('&EPDID=');
    }

    const search = async (event) => {
        event.preventDefault();
        if (EPDID !== '') {
            const data = await fetchEPDid(handleInput(EPDID));
            // console.log(data);
            setEPDDATA(data);
            setHandle(true);
        }

    }

    const prefetchOnLoad = async () => {
        let data = await getClaimedEPD();
        return data;
    };

    useEffect(() => {
        prefetchOnLoad().then(data => {
            setAccepted(data);
        });
    }, []);


    const DropdownSearch = ({ data }) => {
        // console.log(data);
        let datafound = data.foundProducts;
        let notFound = data.notFound;
        return (
            <div style={{ display: "flex", flexDirection: "column" }}>
                {datafound.length > 0 && (
                    <>
                        <h2>Found Products</h2>
                        <ul style={{ listStyle: "none", textAlign: "left", padding: "0", margin: "0", maxWidth: "600px" }}>
                            {datafound.map((item, index) => (
                                <li style={{ display: "flex", flexDirection: "row", alignItems: "center", gap: "10px", backgroundColor: "var(--Grey)", margin: "5px", padding: "10px", borderRadius: "10px" }} key={index}>
                                    <div style={{ display: "flex", flexDirection: "column", width: "400px", gap: "5px" }}>
                                        <p style={{ margin: "2px 0", fontSize: "14px" }}>{item.name}</p>
                                        <p style={{ margin: "2px 0", fontSize: "12px", color: "#555" }}>{item.producerName}</p>
                                        <p style={{ fontSize: "12px", }}>(<a href={item.link} target="_blank" rel="noopener noreferrer" style={{ fontSize: "12px", color: "#007bff" }}>{item.epdid}</a>)</p>
                                    </div>
                                    <p style={{ fontSize: "12px", color: "var(--Black)" }}>Is this your Product?</p>
                                    <button
                                        onClick={() => handleAccept(true, item.id, item.epdid)}
                                        style={{ padding: "5px 10px", fontSize: "12px", margin: "0 5px", cursor: "pointer" }}>
                                        Yes
                                    </button>
                                    <button
                                        onClick={() => handleAccept(false, item.id, item.epdid)}
                                        style={{ padding: "5px 10px", fontSize: "12px", margin: "0 5px", cursor: "pointer" }}>
                                        No
                                    </button>
                                </li>
                            ))}
                        </ul>

                    </>
                )}
                {notFound.length > 0 && (
                    <>
                        <h2>Not Found Products</h2>
                        {notFound.map((item, index) => (
                            <p key={index}>{item} Product not found</p>
                        ))}
                    </>
                )}
            </div>
        );
    };



    const handleAccept = (yn, id, epdid) => {
        if (yn === true) {
            if (Accepted.find(item => item.id === id)) {
                alert('Product already accepted.');
            } else {

                setAccepted(prevAccepted => [...prevAccepted, { id, epdid }]);
            }

        }
        setEPDDATA(prevEPDData => {
            const updatedFoundProducts = prevEPDData.foundProducts.filter(item => item.id !== id);
            return { ...prevEPDData, foundProducts: updatedFoundProducts };
        });
    };



    const handleDelete = (id) => {
        const updatedAccepted = Accepted.filter(item => item !== id);
        setAccepted(updatedAccepted);
    }


    const handleTol = () => {
        setTol(!Tol);
    }
    const toggleModal = () => {
        setOpenModal(!openModal);
    };



    const idClaimEPDid = async () => {
        try {
            // Fetch claimed EPDs
            let claimed = await getClaimedEPD();

            // Check if claimed is an array, if not, set it to an empty array to avoid errors
            if (!Array.isArray(claimed)) {
                claimed = [claimed];
                console.log(claimed);

            }

            // Filter out Accepted items that are already claimed
            setAccepted(prevAccepted => prevAccepted.filter(item => !claimed.includes(item.epdid)));

            let idArray = Accepted.map(item => item.epdid);
            // console.log(idArray);

            if (idArray.length === 0) {
                alert('Please accept at least one product.');
                return;
            }

            if (!Tol) {
                alert('Please accept the terms and conditions.');
                return;
            }

            let data = await claimEPDid(Tol, idArray);

            // Clear Accepted array
            setAccepted([]);
            return data;
        } catch (error) {
            console.error('There was an error claiming the EPD ID:', error);
            return null;
        }
    };



    const overlayStyle = {
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    };

    const modalStyle = {
        backgroundColor: 'var(--White)',
        padding: '20px',
        borderRadius: '10px',
        maxWidth: '600px',
        width: '90%',
        maxHeight: '80%',
        overflowY: 'auto',
    };

    const contentStyle = {
        margin: '20px 0',
        textAlign: 'left',
    };

    return (
        <div style={{ display: "flex", flexDirection: "row", width: "1200px" }}>

            <div style={{ width: "800px" }}>
                <h1>Search by EPD ID</h1>
                <p>Search for an EPD by entering the EPD ID.</p>
                <p>Example: EPD-123456 or EPD-123456,EPD-654321 for multiple.</p>
                {/* <p>EPD-SIO-20220324-IBJ1-EN,EPD-HAM-20220202-ICD1-DE,EPD-SLG-20210055-CBE1-DE</p> */}

                <form onSubmit={search} style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                    <input
                        type='text'
                        placeholder='EPD ID'
                        value={EPDID}
                        onChange={(event) => setEPDID(event.target.value)}
                        style={{
                            padding: '10px',
                            fontSize: '16px',
                            borderRadius: '5px',
                            border: '1px solid #ccc',
                            width: '200px',
                            margin: '0px',
                            marginRight: '10px'
                        }}
                    />
                    <button
                        type='submit'
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            padding: '10px',
                            backgroundColor: 'var(--Sand)',
                            color: 'var(--White)',
                            border: 'none',
                            borderRadius: '5px',
                            cursor: 'pointer',
                        }}
                    >
                        <svg
                            stroke="var(--White)"
                            fill="var(--White)"
                            strokeWidth="2"
                            viewBox="0 0 24 24"
                            height="1em"
                            width="1em"
                            xmlns="http://www.w3.org/2000/svg"
                            style={{ marginRight: '5px' }}
                        >
                            <path
                                fill="none"
                                stroke="var(--White)"
                                strokeWidth="2"
                                d="M15,15 L22,22 L15,15 Z M9.5,17 C13.6421356,17 17,13.6421356 17,9.5 C17,5.35786438 13.6421356,2 9.5,2 C5.35786438,2 2,5.35786438 2,9.5 C2,13.6421356 5.35786438,17 9.5,17 Z"
                            ></path>
                        </svg>

                        Search
                    </button>
                </form>
                {handle ? <DropdownSearch data={EPDDATA} k={EPDID} /> : null}

            </div>
            <div style={{ width: "400px", backgroundColor: "var(--Grey)", height: "600px", marginTop: "27px", display: "flex", flexDirection: "column", alignItems: "center", borderRadius: "10px" }}>
                <h2 style={{ display: "flex", justifyContent: "center" }}>Accepted EPD ID</h2>
                <div style={{ margin: "0px", padding: "0px" }}>
                    <p>Antal: {Accepted.length}</p>
                </div>
                <ul style={{ listStyle: "none", width: "375px", height: "240px", overflowY: "scroll", padding: "0", margin: "0", scrollbarWidth: "thin", scrollbarColor: "rgba(0, 0, 0, 0.3) transparent", display: "flex", flexDirection: "column", alignItems: "center" }}>
                    {/*select all EPDIDs without dublicates*/}
                    {[...new Set(Accepted.map((item) => item.epdid))].map((item) => (
                        <li style={{ backgroundColor: "var(--White)", width: "310px", marginTop: "4px", display: "flex", alignItems: "center", justifyContent: "space-between", padding: "1px", borderRadius: "5px", color: "var(--Black)" }}>
                            <p style={{ fontSize: "12px" }}>&nbsp;&nbsp; {item}</p>
                            <MdDeleteForever style={{ color: "var(--Green)", cursor: "pointer" }} onClick={() => handleDelete(item)} />
                        </li>
                    ))}
                </ul>
                <div>

                    <div style={{ display: "flex", alignItems: "center", marginTop: "10px" }}>

                        {Tol ? <ImCheckboxChecked onClick={handleTol} style={{ cursor: "pointer", color: "var(--Green)" }} /> : <ImCheckboxUnchecked onClick={handleTol} style={{ cursor: "pointer", color: "var(--Green)" }} />}
                        <label style={{ fontSize: "10px", maxWidth: "300px", marginLeft: "10px" }}>
                            By checking this box, I confirm that I have read and agree to the
                            <a href="#" onClick={toggleModal}> Terms and Conditions</a>. I understand that by uploading content to Buildtivity, I grant Buildtivity the right to use, modify, and distribute my content, and I accept full responsibility for ensuring that my content does not infringe on any third-party rights.
                        </label>            {openModal && (
                            <div style={overlayStyle} onClick={toggleModal}>
                                <div style={modalStyle}>
                                    <h2>Terms and Conditions</h2>
                                    <div style={contentStyle}>
                                        <p><strong>1. Ownership of Uploaded Content:</strong></p>
                                        <p>By uploading content (including but not limited to images, videos, text, designs, and other materials) to Buildtivity, you affirm that you are the rightful owner of the content or have obtained all necessary rights and permissions to upload and share the content. You also confirm that your content does not infringe on the intellectual property rights, privacy, or any other rights of third parties.</p>

                                        <p><strong>2. Grant of Rights:</strong></p>
                                        <p>By uploading content to Buildtivity, you grant Buildtivity and its affiliates an irrevocable, perpetual, worldwide, royalty-free, and non-exclusive license to use, reproduce, modify, adapt, publish, translate, distribute, publicly perform, and display the content in any media or medium, or any form, format, or forum now known or hereafter developed. This includes the right to use your content for commercial, promotional, and marketing purposes without further notice or compensation to you.</p>

                                        <p><strong>3. Responsibility and Liability:</strong></p>
                                        <p>You are solely responsible for the content you upload to Buildtivity and any consequences arising from your use of the content. Buildtivity does not endorse any content uploaded by users and is not liable for any legal consequences that may arise from your content. You agree to indemnify and hold Buildtivity harmless from any claims, damages, liabilities, and expenses (including legal fees) arising out of your content or your breach of these terms.</p>

                                        <p><strong>4. Content Removal:</strong></p>
                                        <p>Buildtivity reserves the right to remove or disable access to any content at its sole discretion for any reason, including if the content is deemed to violate these Terms and Conditions or is otherwise objectionable.</p>

                                        <p><strong>5. Acceptance of Terms:</strong></p>
                                        <p>By uploading content to Buildtivity, you acknowledge that you have read, understood, and agree to be bound by these Terms and Conditions. If you do not agree to these terms, please refrain from uploading any content to the platform.</p>
                                    </div>
                                    <button onClick={toggleModal} style={{ backgroundColor: "var(--Sand)", color: "var(--Black)", border: "none" }}>Close</button>
                                </div>
                            </div>
                        )}
                    </div>
                    <button onClick={idClaimEPDid} style={{ margin: "30px", border: "none", padding: "5px", cursor: "pointer", borderRadius: "5px", backgroundColor: "var(--Sand)", color: "var(--White)" }} >
                        Godkend
                    </button>



                </div>
            </div>
        </div>
    );
}

export default SearchEngineID;

