import '../../pages/styles/menuProfile.css'
import React, { useState } from 'react';
import {AiOutlineDoubleRight, AiOutlineDoubleLeft} from 'react-icons/ai';
import {useNavigate} from 'react-router-dom';
import { FaUserCircle } from "react-icons/fa";


function MenuProfile(){
    const navigate = useNavigate();

    const [isMenuOpen, setIsMenuOpen] = useState(false);
  
    const toggleMenu = () => {
      setIsMenuOpen(!isMenuOpen);
    };

    const handleLogout = () => {
        localStorage.removeItem('sessionToken'); // Remove the sessionToken from localStorage
        localStorage.removeItem('selectedProjects'); // Remove the selectedProjects from localStorage
        navigate('/login'); 
      };

    const handleSettings = () => {
        navigate('/profile/settings');
    }

    const handleHome = () => {
        navigate('/');
    }

    const handleProjects = () => {
        navigate('/projekter');
    }

    const handleProfileInfo = () => {
        navigate('/profile');
    }




return  (
    <div>
        <button className={`menuButton ${isMenuOpen ? 'open' : ''}`} onClick={toggleMenu}>  {isMenuOpen ? <AiOutlineDoubleLeft size={40}/> : <AiOutlineDoubleRight size={40}/>} </button>
       
       <div className={`profileMenu ${isMenuOpen ? 'open' : ''}`}>
            <FaUserCircle className="MenuMenuIMG" size={150} color='var(--Sand)' />
            <p className="MenuMenuBtn" onClick={handleHome}>Home</p>
            <p className="MenuMenuBtn" onClick={handleProjects}>Projects</p>
            <p className="MenuMenuBtn"onClick={handleProfileInfo}>Profile Info</p>
            <p className="MenuMenuBtn" onClick={handleSettings}>Settings</p>
            <p className="MenuMenuBtn" onClick={handleLogout}>Log out</p>

        </div>
        </div>
    );


}


export default MenuProfile;