import React, { useState } from 'react';
import { CiSquarePlus } from 'react-icons/ci';
import { FaPlus } from 'react-icons/fa';
import { ImCheckmark } from 'react-icons/im';
import { postNewProject, deleteFromProject, getProjects, postProject } from '../APIS/postGetProject';
import '../../pages/styles/dropDownModal.css'


export const DropdownAndModal = ({ItemId }) => {


    const [selectedProjects, setSelectedProjects] = useState([]);

    const [showModal, setShowModal] = useState(false);
    const [newProjectName, setNewProjectName] = useState('');
    const [hoverCreateNewProject, setHoverCreateNewProject] = useState(false);
    const [clickCreateNewProject, setClickCreateNewProject] = useState(false);


    const addNewProject = (newProject) => {
        setSelectedProjects((prevProjects) => {
            const updatedProjects = [...prevProjects, newProject];
            // localStorage.setItem('selectedProjects', JSON.stringify(updatedProjects));

            return updatedProjects;
        });
    };


        




// Modify handleAddToProject
const handleAddToProject = async (projectId) => {
    const response = await postProject(projectId, ItemId);
    if (response.success) {
        await refreshProjectsList(); 
    } else {
        alert(response.message);
    }
};

const refreshProjectsList = async () => {
    const updatedProjects = await getProjects();
    setSelectedProjects(updatedProjects);
};

const handleRemoveFromProject = async (projectId) => {
    const response = await deleteFromProject(projectId, ItemId);
    if (response.success) {
        await refreshProjectsList(); 
    } else {
        alert(response.message);
    }
}

    const handleCloseModal = async () => {
        setShowModal(false);
        setNewProjectName('');
        setClickCreateNewProject(false);

        const updatedProjects = await getProjects();  // Fetch updated projects
        setSelectedProjects(updatedProjects);  // Update the state with the fetched projects
        
    };


    const handleProjectNameChange = (e) => {
        setNewProjectName(e.target.value);
    };
    const newProject = newProjectName;

    const handleSubmit = async (e) => {
        e.preventDefault();

        const response = await postNewProject(newProject, ItemId);
        console.log(response);
        if (response.success) {
            addNewProject(newProject);
            setNewProjectName('');
            const updatedProjects = await getProjects(); // Refresh the project list
            setSelectedProjects(updatedProjects); // Update the state with the new list
           
        } else {
            alert(response.message);
        }
        handleCloseModal();
    };

    
// Fetch projects from the server on open

    const openModal = async () => {
        const projects = await getProjects();  
        setSelectedProjects(projects);  
        setShowModal(true);
    };


  
    return (
        <>
            <div className="flex-container">
                <CiSquarePlus size={32} onClick={(e) => { openModal(); e.stopPropagation(); }} className="modal-trigger" />
            </div> 
    
            {showModal && (
                <div className="modal-background" onClick={handleCloseModal}>
                    <div className="modal" onClick={(e) => e.stopPropagation()}>
                        <div
                            className={`project-creation-container ${hoverCreateNewProject ? 'project-creation-container-hover' : ''}`}
                            onMouseEnter={() => setHoverCreateNewProject(true)}
                            onMouseLeave={() => setHoverCreateNewProject(false)}
                            onClick={() => setClickCreateNewProject(true)}
                        >
                            {clickCreateNewProject ? (
                                <div>
                                    <h2 className="project-title">Create New Project</h2>
                                    <form onSubmit={handleSubmit}>
                                        <label className="margin-10px">Project Name</label>
                                        <input type="text" value={newProjectName} onChange={handleProjectNameChange} />
                                        <button type="submit" className="margin-10px">Add New Project</button>
                                    </form>
                                </div>
                            ) : (
                                <h2 className="project-title">Create New Project</h2>
                            )}
                        </div>
                        { showModal && (

                        <div className="project-list-container">
                        <h2 className="project-add-title">Add to Project</h2>
                        {selectedProjects.length > 0 ? (
                            <ul className="project-list">
                                {selectedProjects.map((project, index) => {
                                    const isItemSelected = project.items && project.items.includes(ItemId);
                                    return (
                                        <li key={index}
                                            className="project-item"
                                            onClick={ async () => 
                                                !isItemSelected && await handleAddToProject(project.id)}
                                        >
                                            {isItemSelected ? (
                                                <>
                                                    <ImCheckmark size={14} className="project-checkmark" />
                                                    <button onClick={(e) => {
                                                        e.stopPropagation();
                                                         handleRemoveFromProject(project.id);
                                                    }}>Remove</button>
                                                </>
                                            ) : (
                                                <FaPlus size={14} className="project-plus" />
                                            )}
                                            {project.ProjectName}
                                        </li>
                                    );
                                })}
                            </ul>
                        ) : (
                            <div className="no-projects">No Projects</div>
                        )}
                    </div>
                    
                    )}
                </div>
            </div>
        )}
    </>
);
};


// {!createOnlyMode && (
//     <div style={{ width: '45%', height: '100%', paddingLeft: '20px', display: 'flex', flexDirection: 'column' }}>

//         <h1>{itemtitle}</h1>
//         <h2 style={{ color: colorCodes.Green }}>Add to Project</h2>
//         <ul style={{ margin: 0, padding: 0, height: 'calc(100% - 40px)', overflowY: 'auto' }}>
//             {selectedProjects.length === 0 ? (
//                 <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
//                     No Projects
//                 </div>
//             ) : (
//                 selectedProjects.map((project, index) => (
//                     <li key={index} style={{ cursor: 'pointer', background: hoveredProject === project.Project_name ? colorCodes.Grey : colorCodes.White, transition: 'background 0.3s', padding: '10px', display: 'flex', alignItems: 'center'
//                         }}
//                         onMouseEnter={() => setHoveredProject(project.Project_name)}
//                         onMouseLeave={() => setHoveredProject(null)}
//                         onClick={() => {
//                             if (!project.items || !project.items.some(item => item.itemTitle === title)) {
//                                 handleAddToProject(project.Project_name);
//                             }
//                         }}
//                     >
//                         {project.items && project.items.some(item => item.itemTitle === title) ? (
//                                 <>
//                                     <ImCheckmark size={14} style={{ marginRight: '5px', color: colorCodes.Green }} />
//                                     {/* the project.projcetid and project.itemtitle are both undefined?? */}
//                                     <button onClick={() => handleRemoveFromProject(project.Project_name)}>Remove</button>
//                                 </>)
//                                 :
//                                 <FaPlus size={14} style={{ marginRight: '5px', color: colorCodes.Black }} onClick={() => handleAddToProject(project.ProjectId)} />
//                         }
//                         {project.Project_name}
//                     </li>
//                 ))
//             )}
//         </ul>
//     </div>
// )}