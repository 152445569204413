import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from 'react-router-dom';
import { CorrectProduct } from "../../components/APIS/uploadAPI";


function Correctpage() {

    const categories = ["facade", "fundament", "gulv", "inderveg", "isolering", "konstruktion", "loft", "prefabrikeredeElementer", "tag"];
    const location = useLocation();
    const navigate = useNavigate(); 

    const {data} = location.state;
    const [formData, setFormData] = useState({
        categories: [],
        compliance: [],
        materials: [],
        declaredUnits: [],
        masseFylde: [],
        pcr: [],
        rsl: [],
    });

    useEffect(() => {
        if (location.state && location.state.data) {
            console.log(data);
            setFormData({
                categories: data.product.categories || [],
                compliance: data.product.compliance || [],
                materials: data.product.materials || [],
                declaredUnits: data.product.declaredUnits || [],
                masseFylde: data.product.masseFylde || [],
                pcr: data.product.pcr || [],
                rsl: data.product.rsl || []
            });
        }
    }, [data]);

    const handleFormChange = (event, prop, index = null) => {
        const { name, value } = event.target;
        let updatedFormData = { ...formData };

        if (index !== null && Array.isArray(updatedFormData[prop])) {
            // Update array item at specific index (like materials)
            const updatedArray = [...updatedFormData[prop]];
            updatedArray[index][name] = value;
            updatedFormData[prop] = updatedArray;
        } else {
            // Update property directly if it's not an array
            updatedFormData[name] = value;
        }

        setFormData(updatedFormData);
    };

    const handleCategoryChange = (event, category) => {
        const { checked } = event.target;
        let updatedCategories = [...formData.categories];  // Clone the current categories

        if (checked) {
            // Add the category if checked
            updatedCategories.push(category);
        } else {
            // Remove the category if unchecked
            updatedCategories = updatedCategories.filter(cat => cat !== category);
        }

        setFormData({
            ...formData,
            categories: updatedCategories
        });
    };

    // Handle form submission
    const handleSubmit = async (e) => {
        e.preventDefault();
        const InitialDeclaredUnit = {
            unit: formData.declaredUnits[0].unit,
            wholeUnit: formData.declaredUnits[0].wholeUnit,
            amount: formData.declaredUnits[0].amount,
            safetyLevel: formData.declaredUnits[0].safetyLevel,
            conversionFactorTo1kg: 0.0,
            conversionFactorTo1Ton: 0.0,
            environmentalImpacts: formData.declaredUnits[0].environmentalImpacts
        }
        const ProductCorrectionRequest = {
            Id: data.product.id,
            Link: data.product.link,
            Categories: formData.categories,
            SubCategory: null,
            relased: false,
            ImageId: data.product.imageId,
            Materials: formData.materials,
            TekniskSpecifikation: [],
            Certifications: [],
            RSL: formData.rsl,
            PCR: formData.pcr,
            MasseFylde:formData.masseFylde,
            version: "",
            EPDID: data.product.epdid,
            ProducentFoundBugInEcoPortal: false,
            ManualTypedDataBug: false,
            Compliance: formData.compliance,
            CorruptPDF: false,
            ProducerName: data.product.producerName,
            ProductClaim: {CVR: ""},
            InitialDeclaredUnit: InitialDeclaredUnit,
            ConvertedDeclaredUnits: []
        }
        console.log(ProductCorrectionRequest);
        try {
            let data = await CorrectProduct(ProductCorrectionRequest);
            navigate('/produkter');  
            return data;
        }
        catch(error) {
            console.error("there was an error correcting data", error);
            return null;
        }
    };

    return (
        <form onSubmit={handleSubmit}>
            <div style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", flexWrap: "wrap" }}>
                <label>Kategorier</label>
                <div style={{ display: "flex", flexDirection: "row", justifyContent: "center", flexWrap: "wrap", width: "60%" }}>
                    {categories.map((category) =>
                        <div style={{ display: "flex" }}>
                            <p>{category + ":"}</p>
                            <input
                                type="checkbox"
                                name="categories"
                                checked={formData.categories.includes(category)}
                                value={category}
                                onChange={(event) => handleCategoryChange(event, category)}
                            />
                        </div>
                    )}
                </div>
                <label>Materialer</label>
                {formData.materials.map((material, index) =>
                    <div style={{ display: "flex" }}>
                        <p>Materiale navn:</p>
                        <input
                            type="text"
                            name="materialName"
                            value={material.materialName}
                            onChange={(event) => handleFormChange(event, "materials", index)}
                        />
                        <p>Kg:</p>
                        <input
                            type="text"
                            name="kg"
                            value={material.kg}
                            onChange={(event) => handleFormChange(event, "materials", index)}

                        />
                        <p>Percentage:</p>
                        <input
                            type="text"
                            name="percentage"
                            value={material.percentage}
                            onChange={(event) => handleFormChange(event, "materials", index)}
                        />
                    </div>)}
                <label>PCR</label>
                {formData.pcr.map((pcr, index) =>
                    <div div style={{ display: "flex" }}>
                        <p>{index + 1 + ":"}</p>
                        <input
                            type="text"
                            name="pcr"
                            value={pcr}
                            onChange={(event) => {
                                const newArray = [...formData.pcr];
                                newArray[index] = event.target.value;
                                setFormData({
                                    ...formData,
                                    pcr: newArray
                                });

                            }}
                        />
                    </div>
                )}
                <label>MasseFylde</label>
                {formData.masseFylde.map((masseFylde, index) =>
                    <div style={{ display: "flex", flexDirection: "row", alignItems: "center", width: "60%", flexWrap: "wrap", justifyContent: "center" }}>
                        <p>Density:</p>
                        <input
                            type="text"
                            name="density"
                            value={masseFylde.density}
                            onChange={(event) => handleFormChange(event, "masseFylde", index)}
                        />
                        <p>Volume:</p>
                        <input
                            type="text"
                            name="volume"
                            value={masseFylde.volume}
                            onChange={(event) => handleFormChange(event, "masseFylde", index)}
                        />
                        <p>Thickness:</p>
                        <input
                            type="text"
                            name="thickness"
                            value={masseFylde.thickness}
                            onChange={(event) => handleFormChange(event, "masseFylde", index)}
                        />
                        <p>Weight:</p>
                        <input
                            type="text"
                            name="weight"
                            value={masseFylde.weight}
                            onChange={(event) => handleFormChange(event, "masseFylde", index)}
                        />
                        <p>ConversionTo1kg:</p>
                        <input
                            type="text"
                            name="conversionTo1kg"
                            value={masseFylde.conversionTo1kg}
                            onChange={(event) => handleFormChange(event, "masseFylde", index)}
                        />
                    </div>
                )}
                <label>Compliance</label>
                {formData.compliance.map((compliance, index) =>
                    <div div style={{ display: "flex" }}>
                        <p>{index + 1 + ":"}</p>
                        <input
                            type="text"
                            name="pcr"
                            value={compliance}
                            onChange={(event) => {
                                const newArray = [...formData.compliance];
                                newArray[index] = event.target.value;
                                setFormData({
                                    ...formData,
                                    compliance: newArray
                                });

                            }}
                        />
                    </div>
                )}
                <label>Enheder</label>
                {formData.declaredUnits.map((declaredUnits, index) =>
                    <div div style={{ display: "flex" }}>
                        <p>Enhed:</p>
                        <input
                            type="text"
                            name="unit"
                            value={declaredUnits.unit}
                            onChange={(event) => handleFormChange(event, "declaredUnits", index)}
                        />
                        <p>Hel enhed:</p>
                        <input
                            type="text"
                            name="wholeUnit"
                            value={declaredUnits.wholeUnit}
                            onChange={(event) => handleFormChange(event, "declaredUnits", index)}
                        />
                        <p>Antal:</p>
                        <input
                            type="text"
                            name="amount"
                            value={declaredUnits.amount}
                            onChange={(event) => handleFormChange(event, "declaredUnits", index)}
                        />
                    </div>
                )}
                <button type="submit" style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    padding: '10px',
                    backgroundColor: 'var(--Sand)',
                    color: 'var(--White)',
                    border: 'none',
                    borderRadius: '5px',
                    cursor: 'pointer',
                    margin: '10px',
                    width: '100px'
                }}>Godkend</button>
            </div>


        </form>
    )
}
export default Correctpage; 