import React from 'react';

import { Link } from 'react-router-dom';
import { DropdownAndModal } from '../../components/Utils/dropdownmodal';
import { MdDeleteForever } from "react-icons/md";
import { deleteFromProject } from '../../components/APIS/postGetProject';

  function limitWords(str, wordLimit) {
    const words = str.split(' ');
    if (words.length > wordLimit) {
        return words.slice(0, wordLimit).join(' ') + '...';
    }
    return str;
}

function NumberWithHover({ number }) {
    // Check if the number is defined and is a number
    if (number === null || number === undefined || typeof number !== 'number' || isNaN(number)) {
      return <div className="number-container-filter">Invalid data</div>;
    }
  
    // Converts the number to scientific notation
    const scientificNumber = number.toExponential(2);
  
    // Formats the number to a fixed number of decimal places for the tooltip
    const fullNumber = number.toFixed(2);
  
    return (
      <span className="number-container-filter" title={fullNumber}>
        <span style={{ width: '70px' }}>{scientificNumber}</span> {/* Set a fixed width */}
      </span>
    );
  }

function DetailBox({  data = {}, billede_id , Project,itemid, id, handledel}) {
    const colorCodes = {
        Green: "#334C31",
        Sand: "#AE8C65",
        White: "#F5F5F7",
        Black: "#212426",
        Grey: "#D7D5D6"
    };

    const certificationImages = {
        "CE": "/images/CE.svg",
        "Cradle_to_cradle": "/images/CradletoCradle.svg",
        "EU_Ecolabel": "/images/euecolabelpng.png",
        "FSC": "/images/fsc-maerket.svg",
        "indeklima_mark": "/images/indeklimamaerke.svg",
        "PEFC": "/images/PEFC.svg",
        "Svanemarke": "/images/svanemærke.svg",
        "allergy_uk" : "/images/allergy_uk.jpg",
        "astmadanamrk" : "/images/astmadanmark.jpg",
        "derblueengel" : "/images/Blauer_Engel_logo.svg",
    };
    const imageStyle = {
        backgroundImage: `url(/images/kategoriimages/${billede_id}.jpg)`,
        height: '300px',
        width: '300px',
        marginRight: '10px',
        backgroundSize: 'cover',
        boxShadow: '5px 5px 10px rgba(0, 0, 0, 0.3)',

    };  
    const smallImagePlaceholderStyle = {
        height: '50px',
        width: '50px',
        marginRight: '5px',
        marginBottom: '5px',
        backgroundColor: colorCodes.Grey,
    };

    const imageContainerStyle = {
        position: 'relative',
        flex: 1, 
        paddingLeft: '10px'
    };

    const titleStyle = {
        fontFamily: 'GOTHICB',
        color: colorCodes.Green,
        fontSize: '24px'
    };

    const imagesBottomStyle = {
        position: 'absolute',
        bottom: '0',
        left: '0',
        display: 'flex',
        flexWrap: 'wrap'
    };

    const maincolumn = {
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: colorCodes.Grey,
        width: '80%',
        maxWidth: '1220px',
        maxHeight: '350px',
        minWidth: '990px',
        padding: '1%',
        margin: '7px 0' , 
        borderRadius: '10px ',
        boxShadow: '5px 5px 5px rgba(0, 0, 0, 0.3)',
    };

    const Categorystyle = {
        fontFamily: 'GothicB, sans-se',
        color: colorCodes.Black,
    };

    const Datastyle = {
        fontFamily: 'CenturyGothic, sans-se',
        color: colorCodes.Black,
    };
    

const renderCertifications = () => {
    return (
        <React.Fragment >
            {data.ce > 0 && <img src={certificationImages["CE"]} alt="CE" style={smallImagePlaceholderStyle} />}
            {data.svanemarke > 0 && <img src={certificationImages["Svanemarke"]} alt="Svanemarke" style={smallImagePlaceholderStyle} />}
            {data.cradleToCradle > 0 && <img src={certificationImages["Cradle_to_cradle"]} alt="Cradle to Cradle" style={smallImagePlaceholderStyle} />}
            {data.indeklimaMark > 0 && <img src={certificationImages["indeklima_mark"]} alt="Indeklima Mark" style={smallImagePlaceholderStyle} />}
            {data.fsc > 0 && <img src={certificationImages["FSC"]} alt="FSC" style={smallImagePlaceholderStyle} />}
            {data.pefc > 0 && <img src={certificationImages["PEFC"]} alt="PEFC" style={smallImagePlaceholderStyle} />}
            {data.euEcolabel > 0 && <img src={certificationImages["EU_Ecolabel"]} alt="EU Ecolabel" style={smallImagePlaceholderStyle} />}
            {data.derBlueEngel > 0 && <img src={certificationImages["derblueengel"]} alt="Der Blue Engel" style={smallImagePlaceholderStyle} />}
            {data.atsmaMark > 0 && <img src={certificationImages["astmadanmark"]} alt="Atsma Mark" style={smallImagePlaceholderStyle} />}
            {data.allergyUk > 0 && <img src={certificationImages["allergy_uk"]} alt="Allergy UK" style={smallImagePlaceholderStyle} />}
        </React.Fragment>
    );
};

const handleDelete = async () => {
  await deleteFromProject(id, itemid);
  handledel();
};


const renderGWPTotal = () => {
  // Check if data and gwpValues are available
  if (!data || !data.gwpValues || !Array.isArray(data.gwpValues) || data.gwpValues.length === 0) {
    return <p>Loading GWP data...</p>; // Placeholder content
  }

  // Extract the first object from the gwpValues array
  const gwpTotalEntry = data.gwpValues[0];

  return (
    <div style={{ display: 'flex', flexWrap: 'wrap' }}>
      {Object.keys(gwpTotalEntry).map(key => {
        // Skip the 'gwpUnit' key and any key with a value of zero or null
        if (key === 'gwpUnit' || !gwpTotalEntry[key] || parseFloat(gwpTotalEntry[key]) === 0) {
          return null;
        }

        // Extract and format the value
        const value = parseFloat(gwpTotalEntry[key]);

        return (
          <div key={key} style={{ width: '50%', boxSizing: 'border-box', maxHeight: '30px', fontFamily: 'CenturyGothic' }}>
            <p>
              {key.toUpperCase()}: <NumberWithHover number={value} />
            </p>
          </div>
        );
      })}
    </div>
  );
};


  const renderTechnicalSpecifications = () => {
    if (!data.producentUploadData) {
      return <p>No brand data available</p>;
    }

    return (
      <div>
        {data.producentUploadData?.brand?.map((brandItem, index) => {
          // Ensure tS_value exists and is not null
  
          if (brandItem.value) {
            const value = brandItem.value; // Access the brand's value field
            return <p key={index}>{value}</p>;
          }
          return null;
        })}
      </div>
    );
  };
  
  
        
return (
        <div style={maincolumn}>
            <div style={{ display: 'flex', alignItems: 'center' }}></div>
            <div style={{ display: 'flex', margin: '10px 0' }}>
                <Link to={{ pathname: `/produkt/${(data.id)}` }}>
                    <div style={imageStyle}></div>
                </Link>
                <div style={imageContainerStyle}>
                    <div style={{ display:'flex', alignItems: 'center'}}>
                    <div style={{ display: 'flex', alignItems: 'center', paddingBottom:'10px' }}>
                        <Link to={{ pathname: `/produkt/${(data.id)}`  }} className="detail-box-link" style={{ textDecoration: 'none', color: 'inherit' }}>
                            <h1 style={titleStyle}>{(data.productName)}</h1>

                        </Link>
                        {
                        !Project ? (
                          <div>
                            <DropdownAndModal ItemId={data.id} />
                          </div>
                        ) : (
                          <div style={{cursor:'pointer'}}>
                            <MdDeleteForever onClick={handleDelete} size={30} color={colorCodes.Green} />
                          </div>
                        )
                      }

                    </div>
                    </div>
                        
                    <p style={{ color: "#212426" ,maxWidth: '90%'}}>{typeof data["description"] === 'string' ? (limitWords(data["description"],30)) : 'N/A'}</p>
                    <Link to={{ pathname: `/produkt/${(data.id)}` }}>
                        <div style={imagesBottomStyle}>
                            {renderCertifications()}
                        </div>
                    </Link>
                </div>

                <div style={{ paddingLeft: '20px', maxWidth:'290px',maxHeight:'350' }}>
                    
                <div>
                    <p style={Categorystyle}>producent: </p>
                    <p style={Datastyle}>
                        {
                        (data.producerName && Object.keys(data.producerName).length > 0) ? data.producerName : 'N/A'
                        }
                    </p>
                    </div>

                    <div>
                        <p style={Categorystyle}>Materiale:</p>
                        <p style={Datastyle}>{data.subCategory ?? 'N/A'}</p>
                    </div>
                    <div>
                        <p style={Categorystyle}>Brand Klasse:</p>
                        <div style={Datastyle}>
                           {renderTechnicalSpecifications()}
                        </div>
                        </div>

                    <div>
                    <p style={Categorystyle}>Environmental Impacts:</p>
                 
                    {renderGWPTotal()}
                
                    </div>
                </div>
            </div>
        </div>
    );
}
export default DetailBox;
