import React from 'react';
import { AiOutlineLoading } from 'react-icons/ai';
import './loader.css';


const Loader = ({ sizex, colors }) => {
    const colorCodes = {
        Green: "#334C31",
        Sand: "#AE8C65",
        White: "#F5F5F7",
        Black: "#212426",
        Grey: "#D7D5D6"
    };
  
    return (
        <div className="loader">
            <AiOutlineLoading className="loader-icon" size={sizex} color={colorCodes[colors]} />
        </div>
    );
    // use case <Loader sizex={78} colors="Sand"/>
};

export default Loader;