import React, { useState, useEffect, useMemo } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import './DetailPage.css';
import MaterialTable from './tabel.js';
import { DropdownAndModal } from '../../components/Utils/dropdownmodal';
import { fetchItemData } from '../../components/APIS/categoryAPI';





function generateTableData(product) {
  if (!product || !product.declaredUnits || product.declaredUnits.length === 0) {
    return { categories: [], rows: [] };
  }

  // A set to gather all unique module namesN
  const uniqueModules = new Set();

  // Collect all unique modules from environmental impacts
  product.declaredUnits.forEach(unit => {
    if (unit.environmentalImpacts) {
      unit.environmentalImpacts.forEach(impact => {
        impact.values.forEach(valueObj => {
          uniqueModules.add(valueObj.module);
        });
      });
    }
  });

  // Convert the set to an array and create categories including "Parameter", "Unit", and unique module names
  const categories = ['Parameter', 'Unit', ...Array.from(uniqueModules)];

  // Generate the rows
  const rows = product.declaredUnits.flatMap(unit => {
    return unit.environmentalImpacts.map(impact => {
      // Create the base row with the parameter and unit
      const row = [impact.parameter || "N/A", impact.unit || "N/A"];
      
      // Add values for each module, defaulting to "N/A" if not present
      uniqueModules.forEach(module => {
        const valueObj = impact.values.find(v => v.module === module);
        row.push(valueObj ? valueObj.value : "N/A");
      });

      return row;
    });
  });

  return { categories, rows };
}






function DetailPage() {
  // Destructure both mainCategory and itemTitle from useParams
  const { id } = useParams();

  const location = useLocation();
  const initialData = useMemo(() => location.state?.data || {}, [location.state]);
  const [product, setProduct] = useState(initialData);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setIsLoading(true);

    fetchItemData(id)
      .then(data => {
        
        setProduct(data);
        console.log(data);
     
        setIsLoading(false);
       
      })
      .catch(err => {
        console.error(err);

        setIsLoading(false);
      });

    
  }, [id]);

  // console.log(product);
  


  const certificationImages = {
    "CE": "/images/ce.svg",
    "Cradle_to_cradle": "/images/CradletoCradle.svg",
    "EU_Ecolabel": "/images/euecolabelpng.png",
    "FSC": "/images/fsc-maerket.svg",
    "indeklima_mark": "/images/indeklimamaerke.svg",
    "PEFC": "/images/PEFC.svg",
    "Svanemarke": "/images/svanemærke.svg",
    "allergy_uk" : "/images/allergy_uk.jpg",
    "astmadanamrk" : "/images/astmadanmark.jpg",
    "derblueengel" : "/images/Blauer_Engel_logo.svg",
  };






  const technicalSpecsData = useMemo(() => {
    if (!product?.manualTypedData?.producentUploadData?.tekniskSpecifikation) {
      return { categories: [], rows: [] };
    }
  
    const categories = ['Parameter', 'Unit','Value' ];
    const rows = product.manualTypedData.producentUploadData.tekniskSpecifikation.map(spec => {
      const parameter = spec.parameter || 'N/A';
      const value = spec.value || 'N/A';
      const unit = spec.unit || 'N/A';
      return [parameter, unit, value];
    });
  
    // Add the missing return statement
    return { categories, rows };
  }, [product]);
  
  

  const materialsData = () => {
    let prod = product.manualTypedData;
    // Check if the product and its materials property are defined and are arrays
    if (!prod || !Array.isArray(prod.materials)) {
      return { categories: [], rows: [] };
    }
  
    // Define categories (column headers)
    const categories = ['Material Name', 'Kg', 'Percentage'];
  
    // Map materials data to rows
    const rows = prod.materials.map(material => {
      const name = material.materialName || 'N/A'; // Use 'N/A' if materialName is not defined
      const kg = material.kg !== undefined ? String(material.kg) : 'N/A'; // Convert kg to string if defined, otherwise 'N/A'
      const percentage = material.percentage !== undefined ? String(material.percentage) : 'N/A'; // Convert percentage to string if defined, otherwise 'N/A'
      return [name, kg, percentage]; // Each row is an array of values
    });
  
    return { categories, rows };
  };
  
  
  
  
  const renderCertifications = () => {
    // Assuming product.certifications is an array of enumerated certification keys
    const certificationMap = {
        1: "CE",
        2: "Svanemarke",
        3: "Cradle_to_cradle",
        4: "indeklima_mark",
        5: "FSC",
        6: "PEFC",
        7: "EU_Ecolabel",
        8: "derblueengel",
        9: "astmadanmark",
        10: "allergy_uk",
    };

      return (
          <React.Fragment>
              {product.certifications.map((certification) => {
                  const certificationKey = certificationMap[certification]; // Get the certification name
                  if (certificationKey) {
                      return (
                          <img
                              key={certificationKey}
                              src={certificationImages[certificationKey]}
                              alt={certificationKey}
                              className="smallImagePlaceholderStyle_detail"
                          />
                      );
                  }
                  return null;
              })}
          </React.Fragment>
      );
  };







  const tableData = {
    "Environmental Impact": generateTableData(product),
    "Materials": materialsData(product),
    "Technical Specifications": technicalSpecsData,

  };

  const [selectedTable, setSelectedTable] = useState("Environmental Impact");
;  


  return (
    <div className="page-container_detail">
      <div className="detail-container_detail">
        <div className="largeImageStyle_detail">
        <img  className="detailpage-Image " style={{width: '350px', boxShadow: '5px 5px 5px rgba(0, 0, 0, 0.3)'}} src={`/images/kategoriimages/${product.imageId}.jpg`} alt="product" />

        </div>


        <div className="detail-content_detail">

          <div>
            <h1>{(product.name)}</h1>
            <DropdownAndModal ItemId={id} />
          </div>
          <h3>Producent: {product.producerName}</h3>
          <h3>Kategori: {product?.manualTypedData?.categories?.length > 0 ? product.manualTypedData.categories.join(', ') : 'N/A'}</h3>
         

          <p style={{color : 'black'}}>{} Placeholder need something perm</p>





          <div style={{marginTop: '50px'}}>
              {isLoading ? <p>Loading...</p> : renderCertifications()}
              </div>


        </div>
      </div>

      <div className="table-selector">
        {Object.keys(tableData).map(tableName => (
          <button
            key={tableName}
            onClick={() => setSelectedTable(tableName)}
            className={selectedTable === tableName ? 'active' : ''}
          >
            {tableName}
          </button>
        ))}
      </div>

      {selectedTable && (
        <MaterialTable title={selectedTable} data={tableData[selectedTable] || {}} />
      )}
    </div>
  );
}

export default DetailPage;