
function ProcessingComponent() {
    return (
        <div>
            <h1>Processing</h1>
            <p>Processing content</p>
        </div>
    );
}

export default ProcessingComponent;